@media (max-width:767px) {
    /* Default CSS */

    .navbar-nav .nav-item{
        position: relative;
        padding: 10px 8px;
        border-bottom: 1px solid ;
    }

    #referral-bread {
        margin-bottom: -6rem;
    }

    #progress-pad {
        padding: 25px !important;
    }

    .choose-area .choose-content {
        padding: 40px 25px;
    }

    .why_choose_us {
        display: none;
    }

    .counder-border1,
    .counder-border2,
    .counder-border3 {
        border-right: none;
    }

    .pricing-section .pricing-card .pricing-feature {
        padding: 69px 0px 40px;
        height: 14rem;
    }


    .service-para {
        color: #00c1a2;
        font-size: 20px;
        font-weight: 500;
    }

    .service-list li {
        list-style: decimal;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 2.2;
    }

    #service-pad {
        padding: 25px !important;
    }

    .ref-test {
        margin-top: 5rem;
        padding-right: 0px;
    }

    #aboutC4-1 {
        padding: 24px 18px;
    }

    #aboutC4-2 {
        padding: 24px 18px;
    }

    #c4-second {
        margin-top: 2rem;
    }

    #whatWeDo-pad {
        padding: 30px 33px;
    }

    .service-img {
        height: 25%;
        background-color: #FFFFFF;
        margin-bottom: 0.5rem;
    }

    .features-section .features-card {
        text-align: center;
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.1);
        padding: 15px 20px;
        margin-bottom: 30px;
        transition: 0.5s;
        position: relative;
        background: #FFFFFF;
        z-index: 1;
        height: 15rem;
    }

    .video-overlay {
        background-position: center center;
        background-size: cover;
        position: relative;
        height: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        z-index: 0;
    }


    .tabview-img {
        width: 100% !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        object-fit: cover !important;
        height: 100% !important;
        display: block;
    }

    .whatWeDo-section {
        margin-top: -3rem;
    }

    .name-hit {
        color: #00c1a2 !important;
        font-weight: 700;
        font-size: 26px;
        line-height: 1.4;
    }


    .whatWeDo-img {
        background-position: center center;
        background-size: cover;
        position: relative;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        padding: 0px 0px 13px 0px;
    }

    .aboutc4-2img {
        background-position: center center;
        background-size: cover;
        position: relative;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        padding: 0px 0px 13px 0px;
    }

    .black-box2 {
        border: 1px solid;
        position: relative;
        top: -29px;
        left: 34px;
        font-size: 22px;
        padding: 14px 0px 9px 0px;
        width: 80%;
        background: #1e2736;
        text-align: center;
    }

    .black-box1 {
        border: 1px solid;
        position: relative;
        top: -30px;
        left: 34px;
        font-size: 22px;
        padding: 15px 0px 10px 0px;
        width: 80%;
        background: #1e2736;
        text-align: center;
    }


    .c4-para1 {
        padding: 0px 10px 0px 10px;
    }

    .c4-para2 {
        padding: 0px 10px 0px 10px;
    }


    #referral-img {
        position: relative;
        background-position: center center;
        background-size: cover;
        padding-right: 0rem;
    }

    .platform-box {
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid rgba(201, 201, 201, 0.5);
        height: auto;
    }

    .c4-container {
        padding: 20px;
    }

    #card-service {
        height: 21rem;
        padding: 15px 10px;
    }


    .about-text1 {
        position: relative;
        background: #1e2736;
        padding: 32px 16px;
        margin: 0 15px;
    }

    .about-style-two #about-image1 {
        background-image: url(assets/New/HomeWhoWeAre.jpeg);
        background-position: center center;
        background-size: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    // .about-text1 {
    //     position: relative;
    //     background: #1e2736;
    //     padding: 17px 23px;
    //     margin: 0 0px;
    //     top: 12rem;
    //     left: 0rem;
    // }

    #new-portfolio {
        margin-top: -1rem;
    }


    #new-whatwedo {
        display: block;
    }

    #whatWeDo-newimg {
        background-position: center center;
        background-size: cover;
        height: 285%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    #whatWeDo-section{
        margin-top: 11.5rem;
    }



    p {
        font-size: 14px;
    }

    .pt-100 {
        padding-top: 35px;
    }

    .pb-70 {
        padding-bottom: 20px;
    }

    .pb-100 {
        padding-bottom: 50px;
    }

    .section-title {
        h2 {
            font-size: 20px;
            text-align: center;
        }

        span {
            font-size: 14px;
            margin-bottom: 0px;
        }
    }

    h3 {
        font-size: 20px;
    }

    .theme-button {
        .default-btn {
            padding: 10px 30px;
        }
    }

    #particles-js {
        display: none;
    }

    /*------ Home Page ONE CSS Start ------*/
    /* Header CSS */
    .header-area {
        display: none;
    }

    /* Navbar CSS */
    .navbar-area {
        .other-option {
            display: block;
        }

        .mobile-nav {
            display: block;
            position: relative;

            .navbar-nav {
                height: 300px;
                overflow-y: scroll;
            }
        }

        .mobile-nav .logo {
            position: absolute;
            top: 8px;
            left: 15px;
            z-index: 99999;
            max-width: 90px;
        }

        .mean-container .mean-bar {
            background-color: #1e2736;
            padding: 0;
            border-bottom: 1px solid #00c1a2;
        }

        .mean-container a.meanmenu-reveal {
            padding: 15px 15px 0 0;
        }

        .mobile-nav nav .navbar-nav .nav-item a i {
            display: none;
        }

        .main-nav {
            display: none !important;
        }

        &.sticky-top {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }
    }

    /* Banner CSS*/
    .main-banner {
        height: 100%;

        .banner-text {
            text-align: center;
            padding-top: 115px;
            padding-bottom: 75px;

            h1 {
                font-size: 25px;
                margin-bottom: 25px;
            }

            p {
                font-size: 15px;
                margin-bottom: 30px;
            }
        }

        .banner-image {
            position: relative;
            left: 0;
            right: 0;
            max-width: 100%;
            top: inherit;
            bottom: 40px;
        }

        .banner-text {
            .theme-button {
                .default-btn {
                    margin-right: 5px;
                    padding: 10px 16px;
                    font-size: 14px;
                }

                .video-btn {
                    top: 2px;

                    i {
                        width: 44px;
                        height: 44px;
                        line-height: 43px;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    /* Video Section CSS */
    .video-section {
        padding-bottom: 60px;

        .video-text {
            text-align: center;

            i {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 14px;

                &::after {
                    display: none;
                }
            }

            p {
                position: relative;
                left: 0;
                right: 0;
                font-size: 20px;
                top: 40px;
            }
        }
    }

    /* Theory CSS */
    .theory-section {
        margin-top: 50px;
        text-align: center;

        .theory-area {
            box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.14);
            margin: 0;

            .theory-text {
                padding: 50px 0px;

                h2 {
                    font-size: 20px;
                }

                p {
                    margin-bottom: 15px;
                }
            }

            .theory-img {
                position: relative;
                bottom: 0;
                max-width: 100%;
                right: 0;
                left: 0;
                margin: auto;
                top: inherit;
            }
        }
    }

    /* About CSS */
    .about-section {
        padding-top: 50px;
        text-align: center;

        .about-text {
            .about-us {
                margin-bottom: 30px;

                i {
                    left: 0;
                }
            }

            p {
                margin-bottom: 5px;
                margin-top: -10px;
                // text-align: left;
                padding: 12px;
            }
        }

        .about-image {
            img {
                position: relative;
                max-width: 100%;
                height: 300px;
                margin: auto;
                margin-bottom: 30px;
            }
        }
    }

    /* Feature CSS */
    .features-section {
        .features-card {
            padding: 10px 10px;

            i {
                font-size: 30px;
                width: 70px;
                height: 70px;
                line-height: 70px;
            }
        }
    }

    /* Service CSS */
    .service-section {
        .service-text {
            padding: 20px;
            margin: 20px;

            h2 {
                font-size: 20px;
                text-align: center;
            }

            ul {
                li {
                    padding: 10px 10px 10px 65px;
                    max-width: 300px;
                    font-size: 14px;

                    i {
                        width: 50px;
                        line-height: 45px;
                        height: 45px;
                        font-size: 14px;
                    }
                }
            }
        }

        .service-img {
            width: 100%;
        }
    }

    /* Team CSS */
    .team-section {
        .team-card {
            .team-img {
                img {
                    width: 100%;
                }
            }
        }
    }

    /* Testimonial CSS */
    .testimonial-section {
        .testimonial-slider {
            &::before {
                width: 100%;
                height: 90%;
                bottom: 0;
            }

            img {
                margin: 0 auto 20px;
                max-width: 115px;
            }

            p {
                padding: 0 10px;
            }
        }
    }

    /* Portofolio CSS */
    .portfolio-section {
        .portfolio-item {
            .portfolio-img {
                img {
                    width: 100%;
                    height: 100%;
                }

                .portfolio-text {
                    h3 {
                        font-size: 16px;
                    }

                    .portfolio-btn {
                        font-size: 13px;
                        padding: 8px 15px;
                    }
                }
            }
        }

        .theme-button {
            margin: 10px 0;
        }
    }





    /* Progress CSS */
    .progress-section {
        .progress-image {
            width: 100%;
        }

        .progress-text {
            padding: 40px 25px;

            h2 {
                font-size: 20px;
                margin-bottom: 15px;
            }

            p {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
    }

    /* Blog CSS */
    .blog-section {
        .blog-card {
            .blog-img {
                img {
                    width: 100%;
                }
            }

            h3 {
                font-size: 16px !important;
            }

            .post-info {
                .blog-btn {
                    top: 7px !important;
                }
            }
        }
    }

    /* Contact CSS */
    .contact-section {
        .contact-form {
            padding: 20px;

            .contact-shape {
                display: none;
            }
        }

        .contact-img {
            background-image: none;

            img {
                position: relative;
                display: block;
                width: 100%;
            }
        }

        .p-0 {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

    /* Newsletter CSS */
    .newsletter-section {
        .newsletter-area {
            margin-bottom: 50px;
            padding: 30px;
            text-align: center;

            h2 {
                font-size: 20px;
                margin-bottom: 25px;
                line-height: 1.3;
            }

            .newsletter-form {
                text-align: center;

                .form-control {
                    margin-bottom: 0px;
                    margin-right: 44px;
                }

                .default-btn {
                    position: relative;
                    text-align: center;
                }
            }

            .subscribe-shape {
                display: none;
            }
        }
    }

    /* Footer CSS */
    .footer-area {
        clip-path: none;
        padding-top: 20px;
        padding-left: 5px;

        .footer-widget {
            margin-bottom: 30px;

            h3 {
                margin-bottom: 20px;
            }

            ul {
                li {
                    margin-bottom: 15px;
                }
            }
        }

        .pl-75 {
            padding-left: 0;
        }

        .copyright-area {
            margin-top: 20px;
            text-align: center !important;

            p {
                text-align: center !important;
                margin-bottom: 10px;
                line-height: 1.6;
            }

            ul {
                padding-left: 0;

                li {
                    a {
                        text-align: center !important;
                        font-size: 14px;
                    }
                }
            }

            .text-start,
            .text-end {
                text-align: center !important;
            }
        }
    }

    /*------ Home Page One CSS End ------*/
    /*------ Home Page Two CSS Start ------*/
    /* Banner CSS */
    .banner-style-two {
        .banner-image {
            img {
                position: relative;
                left: 0 !important;
                right: 0 !important;
                margin: auto;
                width: 100% !important;
                height: 16rem;
                margin-bottom: 30px;
                max-width: inherit !important;
            }
        }
    }

    /* About CSS */
    .about-style-two {
        .about-image {
            width: 100%;
        }

        .about-text {
            padding: 20px;
            margin: 0 15px;
        }
    }

    /* Video CSS */
    .video-style-two {
        padding: 100px 0;

        .video-text a i {
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 15px;

            &::after {
                width: 80px;
                height: 80px;
            }
        }
    }

    /* Service CSS */
    .service-style-two {
        margin-top: 30px;

        .service-text ul li {
            margin: 0 auto 20px;
        }
    }

    /* Process CSS*/
    .progress-style-two {
        margin-top: 50px;

        .progress-image {
            z-index: -1;
        }

        .progress-text {
            margin: 15px;
        }
    }

    /* Price CSS */
    .pricing-section {
        .pricing-card {
            padding: 15px 10px;

            &::before {
                height: 175px;
                clip-path: none;
            }

            .price-header {
                margin-bottom: 55px;

                i {
                    top: 90px;
                    width: 90px;
                    height: 90px;
                    font-size: 50px;
                    line-height: 80px
                }
            }

            .pricing-feature {
                ul {
                    li {
                        font-size: 14px;
                    }
                }
            }

            &.price-card-two::after {
                display: none;
            }

            &.price-card-three {
                &::before {
                    clip-path: none;
                }
            }
        }
    }

    /* Contact CSS */
    .contact-section {
        text-align: center;

        .contact-form {
            .contact-text {
                h3 {
                    font-size: 23px;
                }

                p {
                    font-size: 15px;
                }
            }
        }
    }

    /*------ Home Page Two CSS End ------*/
    /*------ About Page CSS Start ------*/
    /* Theory CSS */
    .theory-style-two {
        margin-bottom: 50px;
        margin-top: 0;
    }

    /* Video CSS */
    .video-style-three {
        padding-top: 50px;
        padding-bottom: 50px;

        .video-text {
            a {
                i {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;

                    &::after {
                        width: 70px;
                        height: 70px;
                        top: -5px;
                        left: -5px;
                    }
                }
            }
        }
    }

    /*----- About Page CSS End ------*/
    /*------ Service Page CSS Start ------*/
    .service-title {
        height: 100%;
        padding-top: 95px;
        padding-bottom: 36px;

        h2 {
            font-size: 30px !important;
        }
    }

    .service-style-three {
        .service-card {
            padding: 20px 10px;

            h3 {
                font-size: 18px !important;
            }
        }
    }

    /*------ Service Page CSS End ------*/
    /*------ Portfolio Page CSS Start ------*/
    .portfolio-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Portfolio Page CSS End ------*/
    /*------ Portfolio Page TWO CSS Start ------*/
    .portfolio-style-two {
        .portfolio-menu {
            li {
                margin-bottom: 10px;
                padding: 5px 15px;
                display: block;
            }
        }
    }

    /*------ Portfolio Page TWO CSS End ------*/
    /*------ Pricing Page CSS Start ------*/
    .pricing-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Pricing Page CSS End ------*/
    /*------ 404 Page CSS Start ------*/
    .error-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ 404 Page CSS End ------*/
    /*------ Team Page CSS Start ------*/
    .team-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Team Page CSS End ------*/
    /*------ Privacy Page CSS Start ------*/
    .privacy-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    .privacy-section {
        h2 {
            font-size: 20px;
        }

        .privacy-text {
            h3 {
                font-size: 16px;
            }
        }
    }

    /*------ Privacy Page CSS End ------*/
    /*------ Blog Page CSS Start ------*/
    .blog-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Blog Page CSS End ------*/
    /*------ Blog Details Page CSS Start ------*/
    .blog-details-area {
        .blog-description {
            .article-title {
                font-size: 20px;
                line-height: 1.4;
            }

            .blog-comment {
                padding: 20px;
                text-align: center;

                .default-btn {
                    font-size: 14px;
                    padding: 10px 20px;
                }
            }

            .article-info ul li {
                margin-right: 5px;
            }
        }

        .blog-category {
            ul {
                li {
                    a {
                        font-size: 15px;
                    }
                }
            }
        }

        .photo-gallery {
            text-align: center;

            a {
                img {
                    padding-left: 0;
                }
            }
        }

        .tags {
            text-align: center;
        }
    }

    /*------ Blog Details Page CSS End ------*/

    /*------ FAQ Page CSS Start ------*/
    .faq-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    .faq-area {
        h2 {
            font-size: 20px;
        }

        .question {
            padding-top: 10px;
            padding-right: 20px;
            padding-bottom: 10px;
            font-size: 15px;
            font-weight: 500;
            padding-left: 20px;
        }

        .faq-t {
            top: -45px;
        }
    }

    /*------ FAQ Page CSS End ------*/

    /*------ Contact Page CSS Start ------*/
    .contact-title {
        height: 100%;
        padding-top: 100px;
        padding-bottom: 44px;

        h2 {
            font-size: 30px !important;
        }
    }

    .contact-area {
        .contact-box {
            margin-top: 50px;
            padding: 40px 0px;

            .contact-card {
                margin-bottom: 20px;
            }
        }
    }

    .map {
        height: 300px;
        margin-bottom: 50px;

        iframe {
            height: 300px;
        }
    }

    /*------ Contact Page CSS End ------*/
    .about-title {
        height: 220px;
    }

    .about-title {
        .title-text {
            margin-top: 55px;
        }
    }
}

@media (min-width:768px) and (max-width:991px) {
    /*------ Home Page One CSS Start ------*/
    /* Default CSS */

    .navbar-nav .nav-item{
        position: relative;
        padding: 10px 8px;
        border-bottom: 1px solid;
    }


    .name-hit {
        color: #00c1a2 !important;
        font-weight: 700;
        font-size: 27px;
        line-height: 1.4;
    }

    .name-para {
        color: #FFFFFF;
        font-size: 14px;
    }

    #aboutC4-1 {
        padding: 20px 20px;
    }

    #aboutC4-2 {
        padding: 24px 18px;
    }

    // #c4-second{
    //     margin-top: 2rem;
    // }
    .about-section .about-text p {
        // color: #808291;
        // margin-bottom: 25px;
        // max-width: none;
        color: #808291;
        margin-bottom: 9px;
        max-width: none;
        font-size: 14px;
    }

    #whatWeDo-pad {
        padding: 65px 65px;
    }

    #name-para {
        color: #FFFFFF !important;
    }

    .subtitle {
        font-size: 14px;
        height: 6rem;
    }

    .platform-img {
        width: 20.5rem;
        height: 6rem;
    }

    .counder-border1,
    .counder-border3 {
        border-right: 2px dashed grey;
    }

    .counder-border2 {
        border-right: none;
    }

    .pricing-section .pricing-card {
        padding: 45px 28px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        position: relative;
        margin-bottom: 30px;
        background: #FFFFFF;
    }

    .pricing-section .pricing-card .pricing-feature {
        padding: 75px 0px 40px;
        height: 15rem;
    }

    .ref-test {
        margin-top: 5rem;
        // padding-right: 10px;
    }

    #referral-bread {
        margin-bottom: -6rem;
    }


    .whatWeDo-img {
        background-position: center center;
        background-size: cover;
        position: relative;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }

    .aboutc4-2img {
        background-position: center center;
        background-size: cover;
        position: relative;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }

    .black-box1 {
        border: 1px solid;
        position: relative;
        top: -39px;
        left: 9px;
        font-size: 22px;
        padding: 25px 0px 18px 0px;
        width: 50%;
        background: #1e2736;
        text-align: center;
    }

    .black-box2 {
        border: 1px solid;
        position: relative;
        top: -35px;
        left: 450px;
        font-size: 22px;
        padding: 25px 0px 18px 0px;
        width: 34%;
        background: #1E273A;
        text-align: center;
    }

    .c4-para1 {
        padding: 0px 31px 0px 34px;
    }

    .c4-para2 {
        padding: 0px 31px 0px 34px;
    }

    #referral-img {
        position: relative;
        background-position: center center;
        background-size: cover;
        padding-right: 0rem;
        width: 100%;
    }

    .platform-box {
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid rgba(201, 201, 201, 0.5);
        height: 9.5rem;
    }

    #card-service {
        height: 23rem;
        padding: 15px 10px;
    }



    .about-style-two #about-image1 {
        background-image: url(assets/New/HomeWhoWeAre.jpeg);
        background-position: center center;
        background-size: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .about-text1 {
        position: relative;
        background: #1e2736;
        padding: 70px 80px;
        margin: 0 50px;
        /* margin: 0 55px; */
        /* top: 27rem; */
    }

    #new-portfolio {
        margin-top: 0rem;
    }

    #new-whatwedo {
        display: block;
    }

    #whatWeDo-newimg {
        background-position: center center;
        background-size: cover;
        height: 350%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    #whatWeDo-section{
        margin-top: 21rem;
    }







    .pt-100 {
        padding-top: 40px;
    }

    .pb-70 {
        padding-bottom: 40px;
    }

    .pb-100 {
        padding-bottom: 70px;
    }

    .section-title {
        h2 {
            font-size: 25px;
            // text-align: center;
        }

        span {
            font-size: 14px;
            margin-bottom: 0px;
        }
    }

    .theme-button {
        .default-btn {
            padding: 10px 30px;
        }
    }

    #particles-js {
        display: none;
    }

    /* Header CSS*/
    .header-area {
        .header-left-text {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
    }

    /* Navbar CSS */
    .navbar-area {
        .other-option {
            display: none;
        }

        .mobile-nav {
            display: block;
            position: relative;

            .navbar-nav {
                height: 300px;
                overflow-y: scroll;
            }
        }

        .mobile-nav .logo {
            position: absolute;
            top: 8px;
            left: 15px;
            z-index: 99999;
            max-width: 100px;
        }

        .mean-container .mean-bar {
            background-color: #1e2736;
            padding: 0;
            border-bottom: 1px solid #00c1a2;
        }

        .mean-container a.meanmenu-reveal {
            padding: 15px 15px 0 0;
        }

        .mobile-nav nav .navbar-nav .nav-item a i {
            display: none;
        }

        .main-nav {
            display: none !important;
        }

        &.sticky-top {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }
    }

    /* Banner CSS */
    .main-banner {
        height: 100%;
        text-align: center;

        .banner-text {
            margin: auto;
            padding-top: 150px;
            padding-bottom: 140px;

            h1 {
                font-size: 45px;
                margin-bottom: 30px;
            }

            p {
                margin-bottom: 35px;
            }

            .theme-button {
                .video-btn {
                    top: 2px;

                    i {
                        width: 48px;
                        height: 48px;
                        line-height: 48px;
                        font-size: 16px;
                    }
                }
            }
        }

        .banner-image {
            position: relative;
            margin: auto;
            bottom: 50px;
            top: inherit;
            right: 0;
            left: 0;

            img {
                max-width: 100%;
            }
        }
    }

    /* Video Section CSS */
    .video-section {
        padding-bottom: 75px;

        .video-text {
            text-align: center;

            i {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 14px;
            }

            p {
                position: relative;
                left: 0;
                right: 0;
                font-size: 20px;
                top: 40px;
            }
        }
    }

    /* Theory CSS */
    .theory-section {
        margin-top: 70px;
        text-align: center;

        .theory-area {
            .theory-text {
                padding: 50px 0px;

                h2 {
                    font-size: 20px;
                }

                p {
                    margin-bottom: 15px;
                }
            }

            .theory-img {
                position: relative;
                bottom: 0;
                max-width: 100%;
                right: 0;
                left: 0;
                margin: auto;
                top: inherit;
            }
        }
    }

    /* About CSS */
    .about-section {
        padding-top: 70px;

        .about-image {
            margin: auto;

            img {
                position: relative;
                width: 100%;
                margin: auto;
                max-width: unset;
                margin-bottom: 30px;
            }

            :nth-child(3),
            :nth-child(2) {
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .about-text {
            .section-title {
                margin: 0 auto 0px 0;
            }
        }
    }

    .service-section {
        .service-text {
            padding: 35px;

            h2 {
                font-size: 20px;
            }
        }
    }

    /* Team CSS */
    .team-section {
        .team-card {
            .team-img {
                img {
                    width: 100%;
                }
            }
        }
    }

    /* Portofolio CSS */
    .portfolio-section {
        .portfolio-item {
            .portfolio-img {
                img {
                    width: 100%;
                    height: 100%;
                }

                .portfolio-text {
                    h3 {
                        margin-bottom: 20px;
                    }

                    .portfolio-btn {
                        padding: 10px 20px;
                    }
                }
            }
        }
    }

    /* Progress CSS */
    .progress-section {
        .progress-text {
            padding: 35px;

            h2 {
                font-size: 25px;
            }
        }
    }

    .newsletter-section {
        .newsletter-area {
            text-align: center;

            .subscribe-shape {
                display: none;
            }
        }
    }

    /* Footer CSS */
    .footer-area {
        clip-path: none;
        padding-top: 200px;

        .footer-widget {
            .footer-logo {
                img {
                    margin-top: 0;
                    max-width: 100px;
                }
            }

            ul {
                li {
                    font-size: 14px;
                    margin-bottom: 25px;
                }
            }

            p {
                font-size: 14px;
            }
        }

        .pl-75 {
            padding-left: 0px;
            padding-bottom: 50px;
        }

        .copyright-area {
            margin-top: -10px;

            p {
                font-size: 14px;
                line-height: 1.4;
            }

            ul li a {
                font-size: 15px;
            }
        }
    }

    /*------ Home Page One CSS End ------*/
    /*------ Home Page Two CSS Start ------*/
    .banner-style-two {
        .banner-text {
            .theme-button {
                .video-btn {
                    top: 0;

                    i {
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        font-size: 16px;
                    }
                }
            }
        }

        .banner-image {
            margin: auto;
            max-width: 100%;

            img {
                position: relative;
                max-width: 100%;
                right: 0 !important;
                margin: 0 10px;
            }
        }
    }

    /* About CSS */
    .about-style-two {
        .about-image {
            width: 100%;
        }

        .about-text {
            margin: 0 50px;
        }
    }

    /* Pricing CSS */
    .pricing-section {
        .pricing-card {
            &::before {
                clip-path: none;
                height: 185px;
            }

            .price-header {
                i {
                    top: 90px;
                }
            }

            &.price-card-two {
                &::before {
                    height: 260px;
                }

                &::after {
                    display: none;
                }
            }

            &.price-card-three {
                &::before {
                    height: 185px;
                    clip-path: none;
                }
            }

            .pricing-feature {
                ul {
                    li {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    /* Blog CSS */
    .blog-section {
        .blog-card {
            .blog-text {
                h3 {
                    font-size: 18px;
                }
            }
        }
    }

    /*------ Home Page Two CSS End ------*/
    /*------ About Page CSS Start ------*/
    /* Theory CSS */
    .theory-style-two {
        margin-top: 0;
        margin-bottom: 70px;
    }

    /*------ About Page CSS End ------*/
    /*------ Service Page CSS Start ------*/
    .service-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Service Page CSS End ------*/
    /*------ Portfolio Page CSS Start ------*/
    .portfolio-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Portfolio Page CSS End ------*/
    /*------ Pricing Page CSS Start ------*/
    .pricing-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Pricing Page CSS End ------*/
    /*------ 404 Page CSS Start ------*/
    .error-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ 404 Page CSS End ------*/
    /*------ Team Page CSS Start ------*/
    .team-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Team Page CSS End ------*/
    /*------ Privacy Page CSS Start ------*/
    .privacy-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    .privacy-section {
        h2 {
            font-size: 25px;
        }

        .privacy-text {
            h3 {
                font-size: 18px;
            }
        }
    }

    /*------ Privacy Page CSS End ------*/
    /*------ Blog Page CSS Start ------*/
    .blog-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ Blog Page CSS End ------*/
    /*------ FAQ Page CSS Start ------*/
    .faq-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    /*------ FAQ Page CSS End ------*/

    /*------ Contact Page CSS Start ------*/
    .contact-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }

    .contact-area {
        .contact-box {
            margin-top: 50px;
            padding: 40px 0px;

            .contact-card {
                margin-bottom: 20px;
            }
        }
    }

    .map {
        height: 500px;
        margin-bottom: 70px;

        iframe {
            height: 500px;
        }
    }

    /*------ Contact Page CSS End ------*/
    .about-title {
        height: 300px;
    }

    .about-title {
        .title-text {
            margin-top: 60px;
        }
    }
}

@media (min-width:992px) and (max-width:1199px) {

    .navbar-nav .nav-item{
        position: relative;
        padding: 10px 8px;
        border-bottom: 1px solid ;
    }

    .navbar-expand-lg .navbar-collapse {
        display: none !important;
    }
    .navbar-toggler {
        display: block !important;
    }
  


    .black-box1 {
        border: 1px solid;
        position: relative;
        top: -39px;
        left: 9px;
        font-size: 22px;
        padding: 28px;
        width: 95%;
        background: #1e2736;
        text-align: center;
    }


    .black-box2 {
        border: 1px solid;
        position: relative;
        top: -35px;
        left: 10px;
        font-size: 22px;
        padding: 28px;
        width: 93%;
        background: #1e2736;
        text-align: center;
    }

    .c4-para1 {
        padding: 0px 0px 0px 13px;
    }

    .c4-para2 {
        padding: 0px 10px 0px 0px;
    }

    .platform-box {
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid rgba(201, 201, 201, 0.5);
        height: 10rem;
    }

    .about-image {
        img {
            border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
            box-shadow: 0 0 11px 0 rgba(0, 0, 0, .35);
            z-index: 1;
            animation: wrep 10s infinite linear;
            width: 100%;
            // height: 400px;
            max-width: unset;
            margin-left: -1rem !important;
        }
    }

    #card-service {
        height: 25rem;
        padding: 15px 10px;
    }

    .about-text1 {
        position: relative;
        background: #1e2736;
        padding: 48px 70px;
        margin: 0 15px;
        left: -2.5rem;
    }

    .portfolio-section .portfolio-item .portfolio-img {
        position: relative;
        overflow: hidden;
        height: 14rem;
    }

    .platform-para {
        font-size: 12px;
        color: #FFFFFF;
        height: 5rem;
        padding: 2px;
    }

    

    /* Default CSS */
    .section-title {
        h2 {
            font-size: 30px;
        }
    }

    /*------ Home Page One CSS Start ------*/
    /* Header CSS */
    .header-area {
        .header-left-text {
            ul {
                li {
                    i {
                        font-size: 14px;
                    }
                }
            }
        }
    }

   


    .navbar-area {
        .other-option {
            display: none;
        }

        .mobile-nav {
            display: block;
            position: relative;

            .navbar-nav {
                height: 300px;
                overflow-y: scroll;
            }
        }

        .mobile-nav .logo {
            position: absolute;
            top: 8px;
            left: 15px;
            z-index: 99999;
            max-width: 100px;
        }

        .mean-container .mean-bar {
            background-color: #1e2736;
            padding: 0;
        }

        .mean-container a.meanmenu-reveal {
            padding: 15px 15px 0 0;
        }

        .mobile-nav nav .navbar-nav .nav-item a i {
            display: none;
        }

        .main-nav {
            display: none !important;
        }

        &.sticky-top {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }
    }







    /* Banner CSS */
    .main-banner {
        height: 100%;

        .banner-text {
            max-width: 520px;
            padding-top: 100px;
            padding-bottom: 100px;

            h1 {
                font-size: 50px;
            }
        }

        .banner-image {
            max-width: 340px;
            right: 50px;
            top: 40px;
        }
    }

    /* Theory CSS */
    .theory-section {
        .theory-area {
            .theory-img {
                top: inherit;
                bottom: 0;
                max-width: 445px;
            }

            .theory-text {
                h3 {
                    font-size: 30px;
                }
            }
        }
    }

    /* Service Section */
    .service-section {
        .service-text {
            padding: 60px;

            h2 {
                margin-bottom: 30px;
                font-size: 20px;

            }
        }
    }

    /* Team CSS */
    .team-section {
        .team-card {
            .team-text {
                width: 100%;

                h3 {
                    font-size: 15px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }

    /* Testimonial Section */
    .testimonial-section {
        .testimonial-slider {
            p {
                padding: 0 50px;
            }
        }
    }

    /* Process Section */
    .progress-section {
        .progress-text {
            padding: 50px;

            h2 {
                font-size: 20px;
                margin-bottom: 30px;
            }

            p {
                margin-bottom: 70px;
            }
        }
    }

    /* Blog Section */
    .blog-section {
        .blog-card {
            .blog-text {
                h3 {
                    font-size: 16px;
                }

                .post-info {
                    .blog-btn {
                        top: 7px;
                    }
                }
            }
        }
    }

    /*------ Home Page One CSS End ------*/
    /*------ Home Page Two CSS Start ------*/
    /* Banner CSS */
    .banner-style-two {
        .banner-image {
            img {
                width: 100%;
            }
        }
    }

    /* About CSS */
    .about-style-two {
        background: none;
    }

    /* Price CSS */
    .pricing-section {
        .pricing-card {
            padding: 30px 15px;
            height: 37rem;

            .pricing-feature {
                ul {
                    li {
                        font-size: 16px;
                    }
                }
            }

            &.price-card-two::after {
                top: 140px;
            }
        }
    }

    /* Footer CSS */
    .footer-area {
        .pl-75 {
            padding-left: 13px;
        }

        .footer-widget {
            p {
                font-size: 15px;
            }

            ul {
                li {
                    margin-bottom: 25px;
                }
            }
        }
    }

    /*------ Home Page Two CSS End ------*/
    /*------ About Page CSS Start ------*/
    /* Theory CSS */
    .theory-style-two {
        margin-top: 30px;
        margin-bottom: 100px;
    }

    /*------ About Page CSS End ------*/
}

@media (min-width:1800px) {

    #whatWeDo-pad {
        padding: 140px 75px;
    }

    .about-style-two #about-image1 {
        background-position: center center;
        background-size: cover;
        height: 127%;
        width: 50%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .about-text1 {
        position: relative;
        background: #1e2736;
        padding: 70px 140px;
        margin: 0 0px;
        top: 2rem;
    }

    #new-portfolio {
        margin-top: 5rem;
    }






    /* Home Page CSS Start */
    /* Banner CSS */
    .main-banner {
        .banner-image {
            right: 15%;
            top: 70px;
            max-width: 500px;
        }
    }

    /* Progress CSS */
    .progress-section {
        .progress-text {
            padding: 100px;
        }
    }

    /* Home Page CSS End */
    /* Home Page Two CSS Start */
    /* Banner CSS*/
    .banner-style-two {
        .banner-image {
            right: 25%;
            top: 130px;
            max-width: 500px;

            :nth-child(1) {
                // max-width: 370px;
                top: 20%;
                right: 25%;
            }

            :nth-child(2) {
                max-width: 350px;
                top: 25px;
                right: 10%;
            }

            :nth-child(3) {
                max-width: 265px;
                top: 50%;
                right: 12%;
            }
        }
    }

    .service-section {
        .service-text {
            h2 {
                max-width: 590px;
            }
        }
    }

    /* Home Page Two CSS End */
}


@media (min-width: 580px) and (max-width:768px) and (orientation: landscape) {
    .features-section .features-card {
        text-align: center;
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.1);
        padding: 12px 7px;
        margin-bottom: 30px;
        transition: 0.5s;
        position: relative;
        background: #FFFFFF;
        z-index: 1;
        height: 20rem;
    }

    .about-style-two #about-image1 {
        background-position: center center;
        background-size: cover;
        height: 126%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .about-text1 {
        position: relative;
        background: #1e2736;
        padding: 17px 23px;
        margin: 0 0px;
        top: 21rem;
        left: 0rem;
    }

    #new-portfolio {
        margin-top: 16rem;
    }

    .portfolio-section .portfolio-item .portfolio-img {
        position: relative;
        overflow: hidden;
        height: 14rem;
    }

    .platform-para {
        font-size: 13px;
        /* padding-left: 10px; */
        /* padding-right: 10px; */
        color: #FFFFFF;
        height: 5rem;
        padding: 5px;
    }

    .black-box1 {
        border: 1px solid;
        position: relative;
        top: -30px;
        left: 3px;
        font-size: 22px;
        padding: 15px 0px 10px 0px;
        width: 59%;
        background: #1e2736;
        text-align: center;
    }

    .black-box2 {
        border: 1px solid;
        position: relative;
        top: -29px;
        left: 314px;
        font-size: 22px;
        padding: 14px 0px 9px 0px;
        width: 35%;
        background: #1e2736;
        text-align: center;
    }

    #card-service {
        height: 24rem;
        padding: 15px 10px;
    }

    .features-section .features-card h3 {
        font-weight: 600;
        margin-bottom: 10px;
        transition: 0.5s;
        font-size: 18px;
    }

    .features-section .features-card p {
        margin-bottom: 0;
        font-size: 12px;
        transition: 0.5s;
    }
}

// @media (min-width: 992px){
// .navbar-expand-lg .navbar-collapse {
//     display: inline-grid;
//     flex-basis: auto;
//     justify-content: center !important;
// }
// }
