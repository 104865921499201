/* You can add global styles to this file, and also import other style files */

@import "~animate.css/animate.css";
@import "~aos/dist/aos.css";



/* Color Variable */
$body-color: #717477;
$heading-color: #333333;
$white-color: #FFFFFF;
$page-title-bg: #1e2736;
$green-color: #00c1a2;

/* Font Variable */
$body-font: 'Montserrat', sans-serif;
$heading-font: 'Poppins', sans-serif;

body {
    color: $body-color;
    font-family: $body-font;
    line-height: 1.8;
    font-size: 16px;
}

/* Default CSS Start */
img {
    max-width: 100%;
}

a {
    transition: .5s;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

a:focus {
    text-decoration: none;
}

button {
    outline: 0 !important;
    box-shadow: none;
    border: none;
    background-color: transparent;
}

.form-control:focus {
    box-shadow: 0 0 0 0;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
}

h3 {
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    color: #0f2137;
    font-weight: 600;
}

.pt-100 {
    padding-top: 50px;
}

.pb-70 {
    padding-bottom: 35px;
}

.pb-100 {
    padding-bottom: 35px;
}

.theme-button {
    z-index: 1;
    position: relative;

    .default-btn {
        font-size: 16px;
        font-weight: 500;
        color: $white-color;
        background: linear-gradient(90deg, #00c1a2 0%, #00c1a2 100%);
        padding: 16px 40px;
        position: relative;
        display: inline-block;

        &::before {
            position: absolute;
            content: '';
            border: 1px solid $green-color;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            // background:$white-color;
            z-index: -1;
            transition: .5s;
        }

        // &:hover::before {
        //     top: -10px;
        //     right: -10px;
        // }
    }
}

.section-title {
    margin: 0 auto 50px;
    max-width: 600px;

    span {
        font-size: 16px;
        color: #886a03;
        display: inline-block;
        margin-bottom: 10px;
        font-weight: 600;
        text-transform: uppercase;
    }

    h2 {
        font-size: 38px;
        font-weight: 700;
        margin-bottom: 15px;
    }
}

/* Default CSS End */

/** -------------- Home Page One CSS Start --------------**/
/* Header CSS Start */
.header-area {
    background: #00c1a236;
    padding: 15px 0;

    .header-left-text {
        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                display: inline-block;
                color: #565659;
                font-size: 15px;
                font-family: $heading-font;
                font-weight: 500;
                margin-right: 20px;
                line-height: 1;

                a {
                    color: #565659;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    margin-right: 5px;
                    font-size: 16px;
                    top: 1px;
                    position: relative;
                }
            }
        }
    }

    .header-right {
        .header-social {
            ul {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    line-height: 1;
                    margin-left: 5px;

                    a {
                        i {
                            width: 25px;
                            height: 25px;
                            line-height: 23px;
                            background: linear-gradient(0deg, #00c1a2 25%, #00c1a2 100%);
                            color: $white-color;
                            font-size: 14px;
                            text-align: center;
                            display: inline-block;
                            transition: .5s;
                            border: 1px solid transparent;

                            &:hover {
                                background: $white-color;
                                color: $green-color;
                                border: 1px dashed $green-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Header CSS End */

/* Navbar CSS Start */
.navbar-area {
    .main-nav {
        position: relative;
        // position: absolute !important;
        // background: $white-color;
        // border-bottom:2px solid #fff9e6;
        padding-top: 20px;
        padding-bottom: 20px;

        .navbar {
            padding: 0;

            .navbar-brand {
                padding: 0;
                top: -2px;
                position: relative;
            }

            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: #4a4a4a;
                        font-weight: 500;
                        text-transform: capitalize;
                        line-height: 1;

                        > {
                            i {
                                font-size: 12px;
                            }
                        }

                        &.active {
                            color: $green-color;
                        }
                    }

                    .dropdown-menu {
                        background: $white-color;
                        padding: 10px 0;
                        border: 0;
                        transform: scaleX(0);
                        top: 55px !important;
                        right: 0;

                        .nav-item {
                            .nav-link {
                                border-bottom: 1px dashed $green-color;
                                padding: 10px 15px;
                                position: relative;

                                > {
                                    i {
                                        font-size: 12px;
                                        float: right;
                                    }
                                }

                                &:hover {
                                    color: $green-color;
                                    padding-left: 25px;
                                }

                                &:hover::before {
                                    width: 10px;
                                }

                                &:hover::after {
                                    width: 0px;
                                }

                                &::before {
                                    position: absolute;
                                    content: '';
                                    background: $green-color;
                                    width: 0;
                                    height: 1px;
                                    top: 50%;
                                    left: 0;
                                    right: 0;
                                    transition: .5s;
                                }

                                &.active {
                                    color: $green-color;
                                }

                                &:hover {
                                    color: $green-color;
                                }
                            }

                            .dropdown-menu {
                                left: -100%;
                                top: 0 !important;
                                opacity: 0;
                                visibility: hidden;

                                .nav-item {
                                    .nav-link {
                                        &:hover {
                                            color: $green-color;
                                            padding-left: 25px;
                                        }

                                        &:hover::before {
                                            width: 10px;
                                        }

                                        &:hover::after {
                                            width: 0px;
                                        }

                                        &::before {
                                            position: absolute;
                                            content: '';
                                            background: $green-color;
                                            width: 0;
                                            height: 1px;
                                            top: 50%;
                                            left: 0;
                                            right: 0;
                                            transition: .5s;
                                        }

                                        &.active {
                                            color: $green-color;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .dropdown-menu {
                                    opacity: 1 !important;
                                    visibility: visible;
                                    top: 0px !important;
                                }
                            }
                        }

                        &:last-child {
                            .nav-link {
                                border-bottom: 0px;
                            }
                        }
                    }

                    .dropdown-toggle {
                        &::after {
                            display: none;
                        }
                    }

                    &:hover {
                        .dropdown-menu {
                            transform: scaleX(1);
                        }
                    }
                }
            }

            .other-option {
                display: flex;

                .search-bar,
                .sidebar-toggle,
                .link {
                    position: relative;
                    top: -2px;

                    .search-icon {
                        width: 30px;
                        height: 30px;
                        border: 1px solid $green-color;
                        text-align: center;
                        line-height: 27px;
                        color: $green-color;
                        display: inline-block;
                        cursor: pointer;
                        transition: .5s;

                        &:hover {
                            background: $green-color;
                            color: $white-color;
                            border-radius: 20px;
                        }
                    }

                    .search-form {
                        position: absolute;
                        right: 0;
                        box-shadow: 0 0 15px rgba(0, 0, 0, .15);
                        padding: 15px;
                        background: $white-color;
                        top: 70px;
                        animation: fadeInDown 1s;
                        display: none;
                        z-index: 1;

                        .search-input {
                            height: 50px;
                            width: 230px;
                            padding-left: 20px;
                            border: 1px dashed $green-color;
                            font-size: 20px;
                            background: $white-color;

                            &:focus {
                                border: 1px solid $green-color;
                                outline: 0;
                            }
                        }

                        ::placeholder {
                            color: $body-color;
                            font-size: 18px;
                        }

                        button {
                            position: absolute;
                            top: 23px;
                            right: 25px;
                            border: 1px solid $green-color;
                            border-radius: 17px;
                            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
                            color: $green-color;
                            width: 33px;
                            height: 33px;
                            line-height: 30px;
                        }
                    }
                }

                .sidebar-toggle {
                    .navbar-toggle {
                        width: 30px;
                        height: 30px;
                        border: 1px solid $green-color;
                        text-align: center;
                        line-height: 27px;
                        color: $green-color;
                        display: inline-block;
                        cursor: pointer;
                        transition: .5s;
                        padding: 0;
                        border-radius: 0;
                        margin-left: 10px;

                        &:hover {
                            background: $green-color;
                            color: $white-color;
                        }

                        &:focus {
                            outline: 0;
                            box-shadow: 0 0 0 0;
                        }
                    }
                }
            }
        }
    }

    &.sticky-top {
        animation: .5s ease-in-out fadeInDown;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    }

    .main-nav nav .navbar-nav .nav-item a {
        margin-left: 14px !important;
        margin-right: 14px !important;
    }
}

/* Navbar CSS End */

/* Modal CSS Start */
.modal.fade:not(.in).left .modal-dialog {
    transform: translate3d(-25%, 0, 0);
}

.modal.fade:not(.in).right .modal-dialog {
    transform: translate3d(0%, 0, 0);
}

.modal.fade:not(.in).bottom .modal-dialog {
    transform: translate3d(0, 25%, 0);
}

.modal.right .modal-dialog {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}

.right-modal {
    .modal {
        z-index: 9999999;
        transition: 1s;

        .modal-header {
            img {
                width: 120px;
                padding-top: 5px;
                padding-left: 20px;
            }

            button {
                span {
                    background: $white-color;
                    width: 50px;
                    height: 50px;
                    display: inline-block;
                    border-radius: 40px;
                    line-height: 50px;
                    transition: .5s;
                    box-shadow: 7px 5px 30px rgba(72, 73, 121, .15);

                    &:hover {
                        border-radius: 0px;
                    }
                }
            }
        }

        .modal-body {
            width: 500px;
            padding: 40px;
            background: $white-color;

            .modal-link {
                position: relative;
                margin-bottom: 30px;

                a {
                    display: inline-block;
                    box-shadow: 0 0 10px rgba(0, 0, 0, .10);
                }

                h3 {
                    a {
                        position: absolute;
                        bottom: -8px;
                        left: 0;
                        background: $white-color;
                        width: 100%;
                        height: 50px;
                        text-align: center;
                        padding-top: 15px;
                        color: #3f3f3f;
                        display: inline-block;
                        font-size: 18px;
                    }
                }

                &:hover h3 a {
                    background: $green-color;
                    color: $white-color;
                }
            }
        }

        &.fade {
            transition: .5s linear;
            transform: translateX(100%);
        }

        &.show {
            transform: translateX(0%);
        }
    }
}

.modal.right .modal-content {
    min-height: 100vh;
    border: 0;
}

.modal-body {
    padding: 0;
}

.modal-header .close {
    margin-right: 0;
}

/* Modal CSS End */

/* Banner CSS Start */
.main-banner {
    position: relative;
    height: 700px;

    .banner-text {
        max-width: 600px;

        h1 {
            font-size: 60px;
            font-weight: 700;
            font-family: $body-font;
            color: #080808;
            margin-bottom: 30px;
        }

        p {
            color: #808291;
            margin-bottom: 40px;
        }

        .theme-button {
            .default-btn {
                margin-right: 30px;
            }

            .video-btn {
                color: $heading-color;
                font-weight: 500;
                top: 6px;
                position: relative;

                &:hover {
                    color: $green-color;
                }

                i {
                    color: $green-color;
                    border: 1px solid $green-color;
                    width: 60px;
                    height: 60px;
                    display: inline-block;
                    text-align: center;
                    line-height: 60px;
                    transition: .5s;
                    margin-right: 10px;
                    font-size: 25px;
                    position: relative;
                }

                &:hover i {
                    border-radius: 50px;
                    box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.15);
                }
            }
        }
    }

    .banner-image {
        position: absolute;
        right: 100px;
        top: 100px;
        // max-width: 450px;
        // animation: translatey 8s infinite linear;
    }
}

/* Banner CSS End */

/* Video CSS Start */
.video-section {
    background: #0d3642;
    position: relative;
    padding-bottom: 300px;

    &::before {
        position: absolute;
        content: '';
        background-image: url(assets/img/video-shape.png);
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .video-text {
        position: relative;

        i {
            color: $white-color;
            background: $green-color;
            width: 80px;
            height: 80px;
            text-align: center;
            line-height: 85px;
            display: inline-block;
            font-size: 60px;
            position: relative;
            z-index: 0;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border: 3px solid rgba(255, 101, 80, 0.50);
                animation: pulse 2s ease-out infinite;
                left: 0;
                top: 0;
            }
        }

        p {
            position: absolute;
            left: 150px;
            top: 0;
            font-size: 30px;
            color: $white-color;
            line-height: 1.4;
        }
    }
}

/* Video CSS End */

/* Theory CSS Start */
.theory-section {
    margin-top: -200px;

    .theory-area {
        position: relative;
        background: $white-color;
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.04);

        .theory-text {
            padding: 60px;

            h2 {
                font-size: 35px;
                font-weight: 700;
                text-transform: capitalize;
                margin-bottom: 25px;
                line-height: 1.4;
            }

            p {
                color: $heading-color;
                margin-bottom: 30px;
            }

            .signature-box {
                img {
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 0;
                    color: $heading-color;
                    font-weight: 500;
                }

                span {
                    font-size: 14px;
                    color: $heading-color;
                }
            }
        }

        .theory-img {
            position: absolute;
            right: 0;
            max-width: 550px;
            bottom: 0;
        }
    }
}

/* Theory CSS End */

/* About CSS Start */
.about-section {
    padding-top: 70px;
    background: $white-color;

    .about-image {
        img {
            border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
            box-shadow: 0 0 11px 0 rgba(0, 0, 0, .35);
            z-index: 1;
            animation: wrep 10s infinite linear;
            width: 100%;
            // height: 400px;
            max-width: unset;
            margin-left: -3rem;
        }
    }

    .about-text {
        .section-title {
            margin: 0 auto 25px 0;
        }

        .about-us {
            margin-bottom: 20px;

            i {
                font-size: 40px;
                left: 20px;
                position: relative;
                top: 0;
                line-height: 1;
                margin-bottom: 10px;
                display: inline-block;
                color: #111111;
                z-index: 0;

                &::after {
                    position: absolute;
                    content: '';
                    width: 40px;
                    height: 40px;
                    top: 0;
                    left: -15px;
                    z-index: -1;
                }

                &.icon-one {
                    &::after {
                        background: rgba(132, 186, 63, 0.65);
                    }
                }

                &.icon-two {
                    &::after {
                        background: rgba(255, 124, 0, 0.64);
                        border-radius: 50px;
                    }
                }

                &.icon-three {
                    &::after {
                        background: rgba(255, 199, 10, 0.66);
                        transform: rotate(30deg);
                    }
                }
            }

            p {
                font-size: 14px;
                color: #808291;
                margin-bottom: 0;
            }
        }

        p {
            color: #808291;
            margin-bottom: 25px;
            // max-width: 600px;
        }
    }
}

/* About CSS End */

/* Feature CSS Start */
.features-section {
    .features-card {
        text-align: center;
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.10);
        padding: 15px 20px;
        margin-bottom: 30px;
        transition: .5s;
        position: relative;
        background: $white-color;
        z-index: 1;
        height: 19rem;

        &:hover {
            margin-top: -10px;
            box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.04);
        }

        &:hover p,
        &:hover h3 {
            color: $white-color;
        }

        i {
            font-size: 35px;
            width: 90px;
            height: 90px;
            display: inline-block;
            text-align: center;
            line-height: 90px;
            border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
            margin-bottom: 25px;
            animation: icon-animation 5s ease-in-out infinite;
            border: 1px dashed;
        }

        h3 {
            font-weight: 600;
            margin-bottom: 10px;
            transition: .5s;
            font-size: 20px;
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
            transition: .5s;
        }

        &.feature-one {
            i {
                color: #18a87c;
                background: #daf4ec;
            }

            &:hover {
                background: #1e2736;
            }
        }

        &.feature-two {
            i {
                color: #f0573c;
                background: #ffd7ca;
            }

            &:hover {
                background: #1e2736;
            }
        }

        &.feature-three {
            i {
                color: #3454d1;
                background: #e1e6f8;
            }

            &:hover {
                background: #1e2736;
            }
        }

        &.feature-four {
            i {
                color: #ffc70b;
                background: #fff9e6;
            }

            &:hover {
                background: #1e2736;
            }
        }

        &.feature-five {
            i {
                color: #f26d56;
                background: #ede5fb;
            }

            &:hover {
                background: #1e2736;
            }
        }

        &.feature-six {
            i {
                color: #88a32a;
                background: #eff2e2;
            }

            &:hover {
                background: #1e2736;
            }
        }
    }
}

/* Feature CSS End */

/* Service CSS Start */
.service-section {
    position: relative;

    .service-img {
        position: absolute;
        background-image: url(assets/img/service-img.jpg);
        background-position: center center;
        background-size: cover;
        width: 55%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .service-text {
        position: relative;
        background: #1e2736;
        margin-bottom: 50px;
        margin-top: 50px;
        padding: 50px 100px;

        h2 {
            margin-bottom: 15px;
            color: $white-color;
            font-size: 35px;
            font-weight: 700;
            line-height: 1.4;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                padding: 15px 15px 15px 90px;
                background: $white-color;
                margin-bottom: 20px;
                position: relative;
                color: #808291;
                font-size: 18px;
                font-weight: 500;
                max-width: 450px;
                list-style: none;

                i {
                    background: $green-color;
                    color: $white-color;
                    text-align: center;
                    position: absolute;
                    line-height: 65px;
                    font-size: 25px;
                    height: 100%;
                    width: 60px;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

/* Service CSS End */

/* Team CSS End */
.team-section {
    .section-title {
        max-width: 700px;
    }

    .team-card {
        margin-bottom: 80px;

        &:hover .team-text {
            background: $green-color;
        }

        &:hover .team-text h3 {
            color: $white-color;
        }

        &:hover .team-text p {
            color: $white-color;
        }

        &:hover .team-text .team-social {
            opacity: 1;
            transform: translateY(-15px);
        }

        .team-img {
            position: relative;
        }

        .team-text {
            background: $white-color;
            padding: 25px;
            box-shadow: 0 2px 10px rgba(12, 0, 46, 0.15);
            position: absolute;
            text-align: center;
            z-index: 1;
            width: 230px;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -50px;
            transition: .5s;
            transition: .5s;

            .team-social {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                opacity: 0;
                transition: .5s;

                ul {
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        display: inline-block;

                        i {
                            background: $white-color;
                            color: $heading-color;
                            width: 25px;
                            height: 25px;
                            display: inline-block;
                            line-height: 25px;
                            text-align: center;
                            transition: .5s;
                            font-size: 16px;

                            &:hover {
                                color: $green-color;
                            }
                        }
                    }
                }
            }

            h3 {
                font-size: 18px;
                font-weight: 700;
                transition: .5s;
            }

            p {
                margin-bottom: 0;
                transition: .5s;
            }
        }
    }
}

/* Team CSS End */

/* Testimonial CSS Start */
.testimonial-section {
    background: #0d3642;

    .testimonial-slider {
        text-align: center;
        position: relative;
        z-index: 0;

        &::before {
            position: absolute;
            content: '';
            background: $white-color;
            width: 85%;
            height: 85%;
            left: 0;
            z-index: -1;
            margin: auto;
            right: 0;
            bottom: -20px;
            z-index: -1;
            border-radius: 30px;
        }

        img {
            max-width: 150px;
            width: unset;
            margin: 0 auto 40px;
            right: 0;
            left: 0;
            border: 3px solid $green-color;
            border-radius: 150px;
            padding: 5px;
            background: $white-color;
            box-shadow: 0 0 25px rgba(0, 0, 0, .15);
        }

        h3 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 0;
        }

        span {
            color: $green-color;
            font-size: 16px;
            margin-bottom: 20px;
            display: inline-block;
        }

        p {
            padding: 0 105px;
        }

        .owl-prev,
        .owl-next {
            &:hover {
                background: none;
            }

            span {
                color: $green-color;
                font-size: 16px;
                margin-bottom: 20px;
                display: inline-block;
                width: 45px;
                height: 45px;
                border-radius: 50px;
                line-height: 40px;
                font-size: 30px;
                ;
                text-align: center;
                border: 1px solid $green-color !important;
                transition: .5s;

                &:hover {
                    background: $green-color;
                    color: $white-color !important;
                }
            }
        }
    }

    .testimonial-image {
        background-image: url(assets/img/testimonial-img.jpg);
        background-position: center center;
        background-size: cover;
        height: 100%;
    }
}

/* Testimonial CSS End */

/* Portfolio CSS Start */
.portfolio-section {

    // background: #00c1a20f;
    .portfolio-item {
        cursor: pointer;
        margin-bottom: 30px;

        &:hover .portfolio-img .portfolio-text {
            bottom: 0;
        }

        .portfolio-img {
            position: relative;
            overflow: hidden;
            height: 17rem;

            &::before {
                position: absolute;
                content: '';
                background: #000;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: .3;
            }

            img {
                width: 100%;
                height: 100%;
            }

            .portfolio-text {
                position: absolute;
                bottom: -90px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                transition: .5s;
                background: rgba(0, 0, 0, .5);

                h3 {
                    color: $green-color;
                    font-weight: 700;
                    padding: 15px 0 15px;
                    margin-bottom: 0;
                }


                .portfolio-btn {
                    font-size: 14px;
                    font-weight: 500;
                    background: $green-color;
                    color: $white-color;
                    padding: 10px 20px;
                    display: inline-block;
                    margin-bottom: 20px;
                    border: 1px solid transparent;

                    i {
                        font-size: 10px;
                    }

                    &:hover {
                        border: 1px solid $white-color;
                        background: transparent;
                    }
                }
            }
        }
    }
}

/* Portfolio CSS End */

/* Progress CSS Start */
.progress-section {
    position: relative;
    margin-bottom: 5rem;
    background-color: #1e2736;

    .progress-image {
        background-image: url(assets/img/progress.jpg);
        background-position: center center;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 60%;
        left: 0;
        top: 0;
    }

    .progress-text {
        position: relative;
        background: #1e2736;
        margin-bottom: 50px;
        margin-top: 50px;
        padding: 40px 80px 40px 80px;

        h2 {
            color: $white-color;
            margin-bottom: 15px;
            font-weight: 700;
            font-size: 35px;
            line-height: 1.4;
        }

        p {
            color: $white-color;
            margin-bottom: 15px;
        }

        .progress-inner {
            position: relative;
            margin-bottom: 60px;

            p {
                position: absolute;
                margin-bottom: 0;
                top: -35px;
                color: $white-color;
            }

            span {
                position: absolute;
                top: -35px;
                right: 0;
                color: $white-color;
            }

            .progress {
                height: 6px;
                border-radius: 0;

                .progress-bar {
                    background-color: $green-color;
                    height: 6px;
                }
            }
        }

        :last-child {
            margin-bottom: 0;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                padding: 10px 15px 10px 15px;
                background: $white-color;
                margin-bottom: 20px;
                position: relative;
                color: #808291;
                font-size: 18px;
                font-weight: 500;
                max-width: 450px;
                list-style: none;

                i {
                    background: $green-color;
                    color: $white-color;
                    text-align: center;
                    position: absolute;
                    line-height: 55px;
                    font-size: 25px;
                    height: 100%;
                    width: 60px;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

/* Progress CSS End */

/* Blog CSS Start */
.blog-section {
    .blog-card {
        box-shadow: 0px 2px 25px 0px rgba(12, 0, 46, .10);
        margin-bottom: 30px;
        background: $white-color;

        &:hover .blog-img img {
            transform: scale(1.1);
        }

        &:hover .blog-img .blog-date {
            left: 0;
        }

        .blog-img {
            position: relative;
            overflow: hidden;

            img {
                transition: .5s;
            }

            .blog-date {
                position: absolute;
                background: $green-color;
                width: 55px;
                text-align: center;
                top: 0;
                left: 25px;
                padding: 6px;
                transition: .5s;

                span {
                    color: $white-color;
                    display: inline-block;
                    line-height: 25px;
                }
            }
        }

        .blog-text {
            padding: 25px 25px 65px;

            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 15px;
                transition: .5s;
                line-height: 1.4;

                a {
                    color: #111111;
                }
            }

            .post-info {
                position: relative;

                img {
                    position: absolute;
                }

                a {
                    p {
                        position: absolute;
                        left: 50px;
                        top: 8px;
                        font-size: 14px;
                        color: #808291;
                        margin-bottom: 0;
                        transition: .5s;
                    }
                }

                .blog-btn {
                    position: absolute;
                    right: 0;
                    top: 9px;
                    font-size: 14px;
                    color: #808291;

                    i {
                        font-size: 12px
                    }
                }
            }
        }

        &:hover .blog-text a h3,
        &:hover .blog-text .post-info a p,
        &:hover .blog-text .post-info .blog-btn {
            color: $green-color;
        }
    }
}

/* Blog CSS End */

/* Contact CSS Start */
.contact-section {
    .contact-img {
        position: relative;
        background-image: url(assets/images/Askus.jpeg);
        background-position: center center;
        background-size: cover;


        img {
            position: absolute;
            max-width: 100%;
            height: 100%;
            display: none;
        }
    }

    .contact-form {
        box-shadow: 0px 2px 25px 0px rgba(12, 0, 46, .10);
        padding: 35px;
        background: $white-color;
        position: relative;

        .contact-text {
            padding-bottom: 15px;

            h3 {
                font-weight: 600;
                // margin-bottom: 15px;
            }

            p {
                color: #646464;
                margin-bottom: 0;
            }
        }

        .form-group {
            .form-control {
                height: 40px;
                border: 1px solid $green-color;
                border-radius: 10px;
            }

            .message-field {
                border: 1px solid $green-color;
                width: 100%;
                border-radius: 10px;
                padding-left: 10px;
                padding-top: 10px;

                &:focus {
                    border: 1px solid $green-color;
                    outline: 0;
                }

                .list-unstyled {
                    margin-top: 0;
                }
            }

            ::placeholder {
                color: #464646;
            }
        }

        .default-btn {
            color: $white-color;
            border-radius: 5px;
            padding: 10px 20px;
            margin-top: 0px;
            z-index: 0;
            position: relative;
            transition: .5s;
            font-size: 16px;
            border: 1px solid $green-color;
            margin-bottom: 10px;

            &::before {
                position: absolute;
                content: '';
                background: $green-color;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                top: 0;
                left: 0;
                transform: scale(1);
                transition: .5s;
                z-index: -1;
            }

            &:hover::before {
                transform: scale(0);
            }

            &:hover {
                color: $green-color;
                border: 1px solid $green-color;
            }
        }

        .contact-shape {
            position: absolute;
            right: 0;
            bottom: 12px;
            animation: translatey 5s infinite linear;
            height: 5rem;
            opacity: 0.7;
        }
    }
}

/* Contact CSS End */

/* Newsletter CSS Start */
.newsletter-section {
    .newsletter-area {
        background: $green-color;
        padding: 33px;
        margin-bottom: -140px;
        z-index: 1;
        position: relative;
        text-align: center;

        h2 {
            font-size: 32px;
            font-weight: 700;
            color: $white-color;
            margin-bottom: 35px;
            line-height: 1;
        }

        .newsletter-form {
            position: relative;

            .form-control {
                border: 1px solid $green-color;
                padding: 20px 0 20px 20px;
                height: 50px;
                background: $green-color !important;
                color: $white-color;
                border-radius: 0;
                margin-right: 5rem;
            }

            ::placeholder {
                color: $white-color;
            }

            .default-btn {
                position: absolute;
                font-size: 16px;
                color: $white-color;
                height: 50px;
                padding: 0 20px;
                top: 0px;
                right: -1px;
                transition: .5s;
                border-left: 1px solid;
                background: $green-color;
                border: 1px solid;

                &:hover {
                    color: $white-color;
                    background: #1e2736;
                }
            }
        }

        .subscribe-shape {
            position: absolute;
            right: 30px;
            bottom: 30px;
            height: 7rem;
            opacity: 0.2;
            // animation: translatey 5s infinite linear;
        }
    }
}

/* Newsletter CSS End */

/* Footer Section CSS Start */
.footer-area {
    background: #1e2736;
    padding-top: 235px;
    clip-path: polygon(53% 15%, 100% 0, 100% 43%, 100% 78%, 100% 100%, 32% 100%, 0 100%, 0% 43%, 0 0);

    .footer-widget {
        .footer-logo {
            img {
                margin-bottom: 25px;
            }
        }

        p {
            color: $white-color;
            margin-bottom: 18px;
        }

        .footer-social {
            a {
                display: inline-block;
                margin-right: 5px;

                i {
                    color: $green-color;
                    font-size: 16px;
                    margin-right: 10px;
                    transition: .5s;

                    &:hover {
                        color: $white-color;
                    }
                }
            }
        }

        h3 {
            color: $white-color;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 40px;
            display: inline-block;
            position: relative;
            padding-bottom: 5px;

            &::before {
                position: absolute;
                content: '';
                background: #626a5b;
                width: 100%;
                height: 2px;
                bottom: -2px;
                left: 0;
            }
        }

        ul {
            padding-left: 0;
            line-height: 1;
            margin-bottom: 0;

            li {
                display: block;
                margin-bottom: 20px;
                transition: .5s;
                color: $white-color;
                line-height: 1.5;
                font-size: 14px;

                a {
                    color: $white-color;
                    transition: .5s;

                    &:hover {
                        color: $green-color;
                        margin-left: 2px;
                    }

                    &:hover i {
                        color: $green-color;
                    }

                    i {
                        font-size: 12px;
                        top: -1px;
                        position: relative;
                    }
                }

                i {
                    font-size: 12px;
                    transition: .5s;
                }
            }

            :last-child {
                margin-bottom: 0;
            }
        }
    }

    .pl-75 {
        padding-left: 50px;
        margin-top: 6px;
    }

    .copyright-area {
        border-top: 1px solid #4a6871;
        padding: 20px 0;
        margin-top: 40px;

        p {
            color: $white-color;
            margin-bottom: 0;
            // text-align: left;

            a {
                color: $white-color;

                &:hover {
                    color: $green-color;
                }
            }
        }

        .lh-1 {
            line-height: 1;
        }

        ul {
            margin-bottom: 0;

            li {
                display: inline-block;
                margin-left: 25px;
                position: relative;

                &::before {
                    position: absolute;
                    content: '';
                    background: $white-color;
                    width: 5px;
                    height: 5px;
                    border-radius: 10px;
                    right: -15px;
                    top: 6px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }
            }

            :nth-child(2) {
                &::before {
                    display: none;
                }
            }
        }
    }
}

/* Footer Section CSS End */
/** -------------- Home Page One CSS End --------------**/

/** -------------- Home Page Two CSS Start --------------**/
/* Header CSS Start */
.header-style-two {
    background: #071d24;

    .header-left-text {
        ul {
            li {
                color: #95999a;

                a {
                    color: #95999a;
                }
            }
        }
    }
}

/* Header CSS End */

/* Navbar CSS Start */
.navbar-style-two {
    .main-nav {
        background: #1e2736;
        border-bottom: 1px solid #00c1a2 ;
        // background: transparent;
        transition: 0.4s;

        .navbar {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: #ffffff;

                        &:hover {
                            color: $green-color;
                        }
                    }

                    .dropdown-menu {
                        background: #0d3642;
                        box-shadow: 3px -5px 12px rgba(0, 0, 0, .50);

                        .nav-item {
                            .nav-link {
                                color: #9d9eaa;

                                &:hover {
                                    color: $green-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Navbar CSS End */

/* Banner CSS Start */
.banner-style-two {
    background-image: url('../src/assets/images/mainbanner.png');
    background-size: cover;

    animation: fadeIn 3s ease;
    /* Adjust the duration and timing function as needed */

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }



    animation: scaleIn 3s ease-out;
    /* Adjust the duration and timing function as needed */

    @keyframes scaleIn {
        from {
            transform: scale(1.1);
            opacity: 0;
        }

        to {
            transform: scale(1);
            opacity: 1;
        }
    }


    .banner-text {
        h1 {
            color: $white-color;
        }

        p {
            color: $white-color;
        }

        .default-btn {
            &::before {
                background: transparent;
                border: 1px solid $green-color;
            }
        }

        .video-btn {
            color: $green-color !important;

            &:hover {
                color: $white-color !important;
            }

            &:hover i {
                background: $white-color;
            }
        }
    }

    .banner-image {
        position: absolute;
        top: 100px;
    }
}

// /* Banner CSS End */















/* About CSS Start */
.about-style-two {
    padding: 50px 0;
    position: relative;
    // margin-bottom: 5rem;

    .about-image {
        // background-image: url(assets/New/HomeWhoWeAre.jpeg);
        background-position: center center;
        background-size: cover;
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
        left: 0;
    }

    #about-image1 {
        background-image: url('assets/images/C4/c4resize4.jpg');
        background-position: center center;
        background-size: cover;
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
        left: 0;
    }


    .about-text {
        position: relative;
        background: #1e2736;
        padding: 70px 80px;

        p {
            color: $white-color;
        }

        .section-title {
            span {
                color: #886a03;
            }

            h2 {
                color: $white-color;
            }
        }

        .about-us {

            i,
            p {
                color: $white-color;
            }
        }
    }
}

/* About CSS End */

/* Video CSS Start */
.video-style-two {
    background-image: url(../src/assets/images/Homepageimg3.jpeg);
    background-position: center center;
    background-size: cover;
    position: relative;
    height: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    z-index: 0;
    margin-bottom: 5rem;
    // margin-top: 5rem;

    &::before {
        position: absolute;
        content: '';
        background: #464544;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .45;
        z-index: -1;
    }

    .video-text {
        a {
            margin-bottom: 38px;
            display: inline-block;

            i {
                color: $white-color;
                background: $green-color;
                width: 80px;
                height: 80px;
                text-align: center;
                line-height: 85px;
                display: inline-block;
                font-size: 50px;
                position: relative;
                z-index: 0;
                border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;

                &::after {
                    position: absolute;
                    content: '';
                    background: #1e273630;
                    width: 100px;
                    height: 100px;
                    top: -10px;
                    left: -10px;
                    z-index: -1;
                    border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
                    animation: wrep 5s infinite ease-in-out;
                }
            }
        }

        p {
            color: $white-color;
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 500;
            text-transform: capitalize;
            line-height: 1;
        }
    }
}

/* Video CSS End */

/* Service CSS Start */
.service-style-two {
    .service-img {
        width: 50%;
    }

    .service-text {
        margin-top: 0;
        margin-bottom: 0;
    }
}

/* Service CSS End */

/* Progress CSS Start */
.progress-style-two {
    .progress-image {
        width: 50%;
        right: 0;
        left: inherit;
        background-image: url(assets/img/testimonial-img.jpg);
    }

    .progress-text {
        margin-top: 0;
        margin-bottom: 0;
    }
}

/* Progress CSS End */

/* Price CSS Start */
.pricing-section {
    .pricing-card {
        padding: 45px 45px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        position: relative;
        margin-bottom: 30px;
        background: $white-color;

        &::before {
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            content: '';
            height: 270px;
            position: absolute;
            background: $green-color;
            clip-path: polygon(0 0, 100% 0, 100% 65%, 0% 100%);
        }

        .price-header {
            position: relative;
            margin-bottom: 100px;

            h2 {
                color: $white-color;
                font-size: 21px;
                font-weight: 700;
                margin-bottom: 5px;
                line-height: 1;
            }

            h3 {
                color: $white-color;
                font-size: 21px;
                font-weight: 700;

                sup {
                    font-size: 20px;
                    font-weight: 400;
                    top: -1px;
                    left: -3px;
                    position: relative;
                }

                span {
                    font-size: 15px;
                    font-weight: 400;
                }
            }

            i {
                left: 0;
                right: 0;
                top: 115px;
                z-index: 0;
                margin: auto;
                width: 100px;
                height: 100px;
                color: #000000;
                font-size: 60px;
                line-height: 90px;
                position: absolute;
                border-radius: 50px;
                display: inline-block;
                background: $white-color;
                border: 5px solid $green-color;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, .15);
            }
        }

        .pricing-feature {
            padding: 75px 0px 40px;
            height: 17rem;

            ul {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    color: #808291;
                    font-size: 18px;
                    margin-bottom: 5px;
                    list-style: none;

                    i {
                        color: $green-color;
                        margin-right: 10px;
                    }
                }

                :last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.price-card-two {
            i {
                z-index: 1;
            }

            &::before {
                height: 250px;
                clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
            }

            &::after {
                position: absolute;
                content: '';
                width: 50px;
                top: 150px;
                left: 0;
                right: 0;
                margin: auto;
                border-left: 90px solid transparent;
                border-right: 90px solid transparent;
                border-top: 100px solid $green-color;
                z-index: 0;
            }
        }

        &.price-card-three {
            &::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 65%);
            }
        }
    }
}

/* Price CSS End */
/**-------------- Home Page Two CSS End --------------**/

/**-------------- About Page CSS Start --------------**/
/* About Title CSS Start */
.about-title {
    height: 250px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

/* About Title CSS End */

/* Theory CSS Start */
.theory-style-two {
    margin-top: 75px;
    margin-bottom: -130px;
    z-index: 1;
    position: relative;

    .theory-area {
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.14);

        .theory-img {
            bottom: 0;
        }
    }

}

/* Theory CSS End */

/* Video CSS Start */
// .video-style-three {
//     padding-top: 340px;
// }

/* Video CSS End */
/**-------------- About Page CSS End --------------**/

/**-------------- Portfolio Page CSS Start --------------**/
.portfolio-title {
    height: 250px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

/**-------------- Portfolio Page CSS End --------------**/

/**-------------- Blog Page CSS Start --------------**/
.blog-title {
    height: 400px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

/**-------------- Blog Page CSS End --------------**/

/**-------------- Blog Details Page CSS Start --------------**/
.blog-details-area {
    .blog-description {
        .article-image {
            margin-bottom: 35px;
            position: relative;

            .blog-date {
                position: absolute;
                background: $green-color;
                width: 55px;
                text-align: center;
                top: 25px;
                left: 25px;
                padding: 6px;

                span {
                    color: $white-color;
                    display: inline-block;
                    line-height: 25px;
                }
            }
        }

        .article-info {
            margin-bottom: 25px;
            position: relative;

            ul {
                margin: 0;
                padding-left: 0;

                li {
                    font-size: 14px;
                    color: $green-color;
                    display: inline-block;
                    margin-right: 15px;
                    font-weight: 700;

                    i {
                        margin-right: 5px;
                        display: none;
                    }
                }
            }
        }

        .article-title {
            font-size: 32px;
            color: #111111;
            font-weight: 600;
            margin-bottom: 25px;
        }

        p {
            font-family: $heading-font;
            margin-bottom: 10px;
        }

        .article-bottom-img {
            margin-bottom: 20px;
            margin-top: 10px;
        }

        .article-meta {
            position: relative;
            padding-top: 15px;
            margin-bottom: 30px;

            &::before {
                position: absolute;
                content: '';
                background: #ebebeb;
                width: 50%;
                height: 1px;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            ul {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    font-size: 14px;
                    margin-right: 15px;
                    color: #808291;

                    i {
                        margin-right: 5px;
                    }
                }
            }
        }

        .blog-nav {
            display: flex;
            margin-bottom: 40px;
            background: #ddd;
            padding: 20px;
            margin-top: 20px;

            .prev-btn,
            .next-btn {
                width: 50%;

                a {
                    color: #3f3f3f;
                    border: 1px solid $green-color;
                    border-radius: 5px;
                    padding: 8px 20px;
                    font-family: $heading-font;
                    display: inline-block;
                    background: $white-color;

                    &:hover {
                        background: $green-color;
                        color: $white-color;
                    }
                }
            }
        }

        .blog-comment {
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
            padding: 30px;
            margin-bottom: 30px;

            h3 {
                font-size: 22px;
                font-weight: 600;
                color: #333333;
                margin-bottom: 25px;
            }

            .form-control {
                height: 60px;
                padding-left: 20px;
                border: 1px solid $green-color;
                width: 100%;
                border-radius: 0;
                background: #fcf6f6;
                margin-bottom: 30px;
            }

            .message-field {
                padding-top: 20px;
                padding-left: 20px;
                border: 1px solid $green-color;
                width: 100%;
                background: #fcf6f6;

                &:focus {
                    outline: 0;
                }
            }

            ::placeholder {
                font-size: 14px;
                color: #a07373;
                font-family: $heading-font;
            }

            .default-btn {
                font-size: 16px;
                font-family: $heading-font;
                font-weight: 600;
                background: $green-color;
                color: $white-color;
                padding: 15px 40px;
                margin-top: 10px;
                border: 1px solid transparent;
                transition: .5s;

                &:hover {
                    background: $white-color;
                    border: 1px dashed $green-color;
                    color: $green-color;
                }
            }

        }
    }

    .blog-search {
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        padding: 20px;
        margin-bottom: 30px;

        form {
            position: relative;

            input {
                border: 1px solid $green-color;
                padding: 10px 20px;
                width: 100%;

                &:focus {
                    outline: 0;
                }
            }

            ::placeholder {
                font-size: 16px;
                font-family: $heading-font;
                color: #333333;
            }

            button {
                position: absolute;
                background: $green-color;
                text-align: center;
                right: 0;
                top: 0;
                width: 50px;
                height: 100%;
                font-size: 18px;
                transition: .5S;

                &:hover {
                    background: $heading-color;
                }

                i {
                    color: $white-color;
                }
            }
        }
    }

    .recent-blog {
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        margin-bottom: 30px;

        h3 {
            font-weight: 600;
            margin-bottom: 20px;
        }

        .recent-post {
            position: relative;
            margin-bottom: 20px;
            border-bottom: 1px dashed #dddddd;
            padding-bottom: 20px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 90px;
                height: 90px;
                border-radius: 5px;
            }

            h3 {
                margin-bottom: 0;

                a {
                    color: $heading-color;
                    font-size: 14px;
                    padding-left: 110px;
                    margin-bottom: 10px;
                    padding-top: 8px;
                    display: inline-block;
                    line-height: 18px;

                    &:hover {
                        color: $green-color;
                    }
                }
            }

            ul {
                padding-left: 110px;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    color: $green-color;
                    font-size: 14px;
                    font-family: $heading-font;
                    margin-right: 10px;

                    i {
                        font-size: 14p
                    }
                }
            }

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    .blog-category {
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;

        h3 {
            font-weight: 600;
            margin-bottom: 20px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                line-height: 1;
                list-style: none;

                a {
                    padding: 15px 20px;
                    display: block;
                    border-left: 2px solid $green-color;
                    position: relative;
                    border-bottom: 1px dashed #dddddd;
                    font-size: 18px;
                    font-weight: 600;
                    color: $heading-color;
                    margin-bottom: 5px;

                    i {
                        position: absolute;
                        top: 12px;
                        right: 0px;
                        font-size: 25px;
                    }

                    &:hover {
                        background: $green-color;
                        color: $white-color;
                    }
                }
            }
        }
    }

    .photo-gallery {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;

        h3 {
            font-weight: 600;
            margin-bottom: 20px;
            padding-left: 20px;
            padding-top: 20px;
        }

        a {
            img {
                padding-left: 20px;
                margin-bottom: 20px;
            }

        }
    }

    .tags {
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;

        h3 {
            font-weight: 600;
            margin-bottom: 20px;
        }

        a {
            background: #e5e5e5;
            color: #333333;
            padding: 5px 15px;
            margin-bottom: 10px;
            display: inline-block;
            border-radius: 10px;
            border: 1px dashed $heading-color;
            text-align: center;
            margin-right: 10px;
            font-size: 13px;
            font-weight: 600;

            &:hover {
                background: $green-color;
                color: $white-color;
            }

        }
    }
}

/**-------------- Blog Details Page CSS End --------------**/

/**-------------- Contact Page CSS Start --------------**/
/* Contact Title Start */
.contact-title {
        background: #1e2736;
        padding-top: 79px;
        padding-bottom: 158px;
  

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

/* Contact Title Start */

/* Contact Section Start */
.contact-area {
    .contact-box {
        margin-top: -100px;
        background: $white-color;
        padding: 60px 35px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);

        .contact-card {
            border: 1px solid $green-color;
            position: relative;
            padding: 21px 8px 2px 8px;
            height: 11rem;
        }

        i {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            border: 1px solid $green-color;
            display: inline-block;
            color: $green-color;
            text-align: center;
            line-height: 38px;
            font-size: 20px;
            position: absolute;
            top: 52px;
            left: 20px;
        }

        h3 {
            padding-left: 65px;
            font-weight: 700;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;
            padding-left: 65px;

            li {
                color: #4a4a4a;
                font-size: 13px;
                list-style: none;
            }
        }
    }
}

.map {
    line-height: 1;
    height: 500px;
    margin-bottom: -120px;

    iframe {
        width: 100%;
        height: 500px;
        border: 0
    }
}

/* Contact Section End */

/* Footer Area Start */
.footer-style-three {
    clip-path: none;
}

/* Footer Area End */
/**-------------- Contact Page CSS End --------------**/

/**-------------- Service Page CSS Start --------------**/
.service-title {
    height: 250px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.service-style-three {
    .service-card {
        text-align: center;
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.10);
        padding: 30px 20px;
        margin-bottom: 30px;
        transition: .5s;
        position: relative;
        border-bottom: 2px solid $body-color;

        &:hover {
            margin-top: -10px;
        }

        &:hover i {
            animation: none;
            border: 1px dashed $green-color;
            color: $green-color;
        }

        i {
            font-size: 35px;
            width: 90px;
            height: 90px;
            display: inline-block;
            text-align: center;
            line-height: 90px;
            border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
            margin-bottom: 25px;
            animation: icon-animation 5s ease-in-out infinite;
            border: 1px dashed;
            transition: .5s;
        }

        h3 {
            font-weight: 600;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            font-size: 16px;
            color: #0f2137;
        }
    }
}

/**-------------- Serice Page CSS End --------------**/

/**-------------- Portfolio Page Two CSS Start --------------**/
.portfolio-style-two {
    .portfolio-menu {
        margin-bottom: 50px;

        ul {
            li {
                cursor: pointer;
                padding: 10px 20px;
                list-style-type: none;
                display: inline-block;
                border: 1px solid $green-color;
                transition: .5s;

                &:hover {
                    background: $green-color;
                    color: $white-color;
                }
            }

            .mixitup-control-active {
                color: $white-color;
                background: $green-color;
            }
        }
    }
}

/**-------------- Portfolio Page Two CSS End --------------**/

/**-------------- Pricing Page CSS Start --------------**/
.pricing-title {
    height: 400px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

/**-------------- Pricing Page CSS End --------------**/

/**-------------- 404 Page CSS Start --------------**/
.error-title {
    height: 400px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.error-section {
    .error-img {
        margin-bottom: 50px;

        img {
            width: 100%;
        }
    }
}

/**-------------- 404 Page CSS End --------------**/

/**-------------- Team Page CSS Start --------------**/
.team-title {
    height: 400px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.footer-style-two {
    padding-top: 100px;
    clip-path: none;
}

/**-------------- Team Page CSS End --------------**/

/**-------------- Team Page Two CSS Start --------------**/
.team-style-two {
    .team-card {
        margin-bottom: 30px;

        .team-img {
            position: relative;

            &:hover .team-text {
                background: rgba(0, 0, 0, .5);
                transform: scale(1);
            }

            img {
                width: 100%;
            }
        }

        .team-text {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, .5);
            color: $white-color;
            transform: scale(0);

            h3 {
                font-size: 25px;
                color: $white-color;
            }

            &:hover {
                background: rgba(0, 0, 0, .5);
            }
        }
    }
}

/**-------------- Team Page Two CSS End --------------**/

/**-------------- Service Page Details CSS Start --------------**/
.service-style-four {
    .service-details-area {
        .service-details-img {
            margin-bottom: 40px;

            img {
                width: 100%;
            }
        }

        h3 {
            position: relative;
            padding-left: 0;
            margin-bottom: 15px;
            font-size: 30px;
        }

        p {
            margin-bottom: 15px;
        }

        .mb-35 {
            margin-bottom: 35px;
        }

        .service-btm-img {
            margin: 10px 0 20px;
        }
    }

    .blog-search {
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        padding: 20px;
        margin-bottom: 30px;

        form {
            position: relative;

            input {
                border: 1px solid $green-color;
                padding: 10px 20px;
                width: 100%;

                &:focus {
                    outline: 0;
                }
            }

            ::placeholder {
                font-size: 16px;
                font-family: $heading-font;
                color: #333333;
            }

            button {
                position: absolute;
                background: $green-color;
                text-align: center;
                right: 0;
                top: 0;
                width: 50px;
                height: 100%;
                font-size: 18px;
                transition: .5S;

                &:hover {
                    background: $heading-color;
                }

                i {
                    color: $white-color;
                }
            }
        }
    }

    .service-category {
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;

        h3 {
            font-weight: 600;
            margin-bottom: 20px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                line-height: 1;
                list-style: none;

                a {
                    padding: 15px 20px;
                    display: block;
                    border-left: 2px solid $green-color;
                    position: relative;
                    border-bottom: 1px dashed #dddddd;
                    font-size: 18px;
                    font-weight: 600;
                    color: $heading-color;
                    margin-bottom: 5px;

                    i {
                        position: absolute;
                        top: 12px;
                        right: 0px;
                        font-size: 25px;
                    }

                    &:hover {
                        background: $green-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/**-------------- Service Page Details CSS End --------------**/

/**-------------- FAQ Page CSS Start --------------**/
.faq-title {
    height: 400px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.faq-area {
    h2 {
        margin-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
        margin-bottom: 50px;
        font-size: 32px;
        font-weight: 600;
        border-bottom: 2px dashed #886a03;
    }

    .topic {
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 0px;

        h3 {
            color: $heading-color;
        }
    }

    .open {
        cursor: pointer;
        display: block;
        padding: 0px;
        height: 60px;

        &:hover {
            opacity: 0.7;
        }
    }

    .expanded {
        background-color: #f5f5f5;
        transition: all .3s ease-in-out;
    }

    .question {
        padding-top: 20px;
        padding-right: 40px;
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        color: #526ee4;
        padding-left: 40px;
    }

    .answer {
        font-size: 16px;
        line-height: 26px;
        display: none;
        margin-bottom: 30px;
        text-align: justify;
        padding-left: 40px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    .faq-t {
        transform: rotate(-45deg);
        display: inline-block;
        position: relative;
        top: -55px;
        left: 5px;
        width: 10px;
        height: 10px;
        background: transparent;
        border-left: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        transition: all .3s ease-in-out;
    }

    .faq-o {
        top: -50px;
        transform: rotate(-224deg);
    }
}

/**-------------- FAQ Page CSS End --------------**/

/**-------------- Privacy Page CSS Start --------------**/
.privacy-title {
    height: 400px;
    background: $page-title-bg;

    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }

                a {
                    color: $white-color;

                    &:hover {
                        color: $green-color;
                    }
                }

                i {
                    font-size: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.privacy-section {
    padding-bottom: 60px;

    .privacy-text {
        margin-bottom: 15px;

        h3 {
            font-size: 20px;
            font-family: "Poppins", sans-serif;
            color: #0f2137;
            text-transform: capitalize;
            margin-bottom: 10px;
            display: inline-block;
        }

        p {
            margin-bottom: 0;
        }
    }

    ul {
        li {
            margin-top: 10px;
            list-style: circle;
            color: #111111;
        }
    }
}

/**-------------- Privacy Page CSS End --------------**/

/**-------------- Back to Top CSS Start --------------**/

/**-------------- Back to Top Page CSS End --------------**/


/**-------------- Pre Loader CSS Start --------------**/
.loader-content {
    background: $white-color;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999999;
    left: 0;
    top: 0;
    text-align: center;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid red;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

/**-------------- Pre Loader CSS End --------------**/

/* Magnific Popup */
.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

/* Animation CSS */
@keyframes icon-animation {
    0% {
        border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
    }

    50% {
        border-radius: 66% 34% 41% 59% / 51% 34% 66% 49%;
    }

    100% {
        border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
    }
}

@keyframes wrep {
    0% {
        border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
    }

    35% {
        border-radius: 67% 33% 39% 61% / 48% 63% 37% 52%;
    }

    70% {
        border-radius: 40% 60% 62% 38% / 32% 41% 59% 68%;
    }

    100% {
        border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
    }
}

@keyframes translatex {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(20px);
    }

    10% {
        transform: translateX(0px);
    }
}

@keyframes translatey {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-15px);
    }

    10% {
        transform: translateY(0px);
    }
}

/* play button animation */
@keyframes pulse {
    from {
        transform: scale(1);
        filter: opacity(0.9);
    }

    to {
        transform: scale(2);
        filter: opacity(0);
    }
}


// now css

#flaticon {
    // margin-left: -1.3rem;
    font-size: 18px;
}

.service-details {
    font-size: 13px;
}

.service-para {

    color: $green-color;
    // margin-bottom: 2rem;
    font-size: 20px;
    font-weight: 500;
}

.transparent-image {
    // opacity: 0.5;
    // height: 450px;
    // width: 550px;

    height: 500px;
    width: 550px;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.35);
}

.service-img {
    height: 28%;
    background-color: $white-color;
    margin-bottom: 0.5rem;
}


.pro-img {
    background-position: center center;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.ser-img {
    background-position: center center;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.homeser-img {
    position: relative;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 35rem;
    left: 0;
    top: 100px;
}

.your-image-class {
    background: $green-color;
    color: $white-color;
    text-align: center;
    position: absolute;
    line-height: 65px;
    font-size: 25px;
    height: 100%;
    width: 20%;
    left: 0;
    top: 0;

}

.c4Model-img {
    position: absolute;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.service-div {
    font-size: 12px;
}


#card-service {
    height: 20rem;
    padding: 15px 10px;
}


.c4Model-para {
    color: #808291 !important;
    font-size: 12px;
    margin-top: 5px;
}

.name-hit {
    color: $green-color !important;
    font-weight: 700;
    font-size: 35px;
    line-height: 1.4;
}

.name-para {
    color: $white-color;
}

.name-h2 {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 35px;
    line-height: 1.4;
}


#service-pad {
    padding: 130px 95px;
}

#progress-pad {
    padding: 130px 100px;
}

.service-list li {
    list-style: decimal;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 2.2;
}

.service-list {
    padding-left: 1.3rem;
}

.icon {
    width: 50px;
    height: 40px;
}


.why_choose_us {
    height: 35rem;
    position: relative;
    background-position: right;
    background-size: cover;
    left: 0;
    top: 50px;
}




@media (min-width: 768px) and (max-width: 991px) {

    #service-pad {
        padding: 35px !important;
    }

    #progress-pad {
        padding: 35px !important;
    }

}








/*----------------------------
    Counterup Item
----------------------------*/
.counter-section-area {
    position: relative;
    margin-top: 25px;
    margin-bottom: 5rem;
}

.counter-section-area.style-01 {
    margin-top: 120px;
}

.counter-section-area.style-02 {
    margin-top: 105px;
}

.counter-section-area.bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
}

.counter-section-area .counter-shape {
    position: absolute;
    top: 0px;
    left: 31%;
    opacity: 0.1;
}

.counter-section-area .counter-shape.style-01 {
    top: 0;
    left: -1%;
}

.counter-section-area .counter-shape-01 {
    position: absolute;
    top: 0px;
    left: 34%;
    opacity: 0.1;
}

.counter-section-area .counter-shape-01.style-01 {
    top: 0;
    left: 2%;
}

.counter-section-area .counter-shape-02 {
    position: absolute;
    bottom: 0;
    right: 30%;
    opacity: 0.1;
}

.counter-section-area .counter-shape-02.style-01 {
    bottom: 0;
    right: -1%;
}

.counter-section-area .counter-shape-03 {
    position: absolute;
    bottom: 0;
    right: 33%;
    opacity: 0.1;
}

.counter-section-area .counter-shape-03.style-01 {
    bottom: 0;
    right: 2%;
}

.counter-section-area #Counter-text {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 600;
    margin-top: 3.5rem;

}

.counter-section-inner {
    background: #fff;
    -webkit-box-shadow: 0px 12px 40px #1e2736;
    box-shadow: 0px 12px 40px #1e2736;
    padding: 60px;
    border-radius: 10px;
}

.counter-section-inner.political {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.counter-item-list li {
    width: 33.3333333333%;
}

.counter-item-list li+li {
    border-left: 1px solid #a8aab1;
}

.count-num {
    font-size: 4rem;
    color: #00c1a2;
    font-weight: 500;

}

.content {
    text-align: center;
    color: #1e2736;
}



.choose-area {

    background: #1e2736;


    .single-choose {
        position: relative;
        transition: 0.3s;
        overflow: hidden;
        text-align: center;
        box-shadow: var(--boxShadow);
        margin-bottom: 30px;

        .icon {
            background: var(--whiteColor);
            border-radius: 4px;
            text-align: center;
            overflow: hidden;
            margin: auto;
            box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
            padding: 10px 20px;

            i {
                font-size: 80px;
                color: var(--darkBlueColor);
                transition: 0.3s;
            }
        }

        h3 {
            text-align: center;
            color: var(--headingColor);
            margin-bottom: 0;
            padding: 10px 5px;
            font-size: 17px;
            background-color: #f1f1f1;
        }

        &:hover {
            transform: translateY(-10px);

            .icon {
                i {
                    color: var(--mainColor);
                }
            }
        }
    }

    .choose-c {
        position: relative;
        margin-top: 75px;

        .content {
            max-width: 600px;
            margin-left: 30px;

            h3 {
                font-size: 30px;
                color: var(--headingColor);
                margin-bottom: 15px;
                font-weight: 600;
            }

            p {
                color: var(--headingColor);
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .box-btn {
                border-radius: 50px;
                margin-top: 15px;
            }
        }
    }
}

.section-title {
    position: relative;
    max-width: 695px;
    margin: -8px auto 10px;
    text-align: start;

    span {
        color: var(--mainColor);
        font-size: 14px;
        position: relative;
        display: inline-block;
        margin-bottom: 8px;
    }

    h2 {
        font-size: 35px;
        text-transform: initial;
        color: var(--headingColor);
        margin-bottom: 10px;
    }
}

.choose-area {
    .choose-content {
        padding: 150px 40px 150px 0px;


        .faq-accordion {
            .faq-item {
                position: relative;
                margin-bottom: 20px;
                box-shadow: var(--boxShadow);

                .faq-header {
                    background-color: var(--whiteColor);
                    box-shadow: var(--boxShadow);
                    padding: 20px 60px 20px 25px;
                    color: var(--darkBlueColor);
                    position: relative;
                    border-radius: 2px;
                    font-size: 18px;
                    font-weight: 600;
                    display: block;
                    cursor: pointer;

                    i {
                        top: 50%;
                        right: 20px;
                        content: "\eb89";
                        position: absolute;
                        transform: translateY(-50%);
                    }
                }

                .faq-content {
                    background-color: var(--whiteColor);
                    padding: 15px 30px 15px 30px;
                    font-size: 16px;

                    p {
                        color: var(--darkBlueColor);
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


.choose-area li {
    padding: 10px 36px 10px 15px;
    background: #FFFFFF;
    margin-bottom: 20px;
    position: relative;
    color: #808291;
    font-size: 18px;
    font-weight: 500;
    max-width: 450px;
    list-style: none;

}

.choose-area ul {
    padding-left: 0rem;
}



.form-control {
    outline: none;
}

.input-group {
    position: relative;
    border: 1px solid #FFFFFF;
}

.btn.secondary {
    color: #fff;
}


.btn.secondary:hover,
.btn.secondary:focus {
    color: #1e2736;
}

.btn i {
    position: relative;
    top: 2px;
    font-size: 29px;
}

.btn.secondary i:hover {
    opacity: 0.7;
}

.btn span {
    position: absolute;
    top: 9px;
    right: 43px;
    font-size: 20px;
}



#service-section .service-text ul li i {
    background: #00c1a2;
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    // line-height: 65px;
    font-size: 25px;
    height: 100%;
    width: 60px;
    left: 0;
    top: 0;



    #service-section .service-text ul li {
        padding: 15px 15px 15px 90px !important;
    }


}

.service-section {
    background-color: #1e2736;
}

// .choose-area .c4Model-service i{
//     line-height: 60px;
//     /* background: #00c1a2; */
//     color: #00c1a2;
//     text-align: end;
//     position: absolute;
//     font-size: 25px;
//     height: 100%;
//     width: 0%;
//     left: 0;
//     top: 0;
//     font-weight: 900;
// }


.choose-area .c4Model-service li {
    position: relative;
    cursor: pointer;
}

.choose-area .c4Model-service i {
    line-height: 60px;
    color: #00c1a2;
    font-size: 25px;
    font-weight: 900;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    transition: color 0.3s;
}

.choose-area .c4Model-service li:hover i {
    color: #1e2736;
}


.choose-area .c4Model-span {
    padding-left: 10px;
}

.platform-para {
    // font-size: 15px;
    // padding-left: 10px;
    // padding-right: 10px;
    // color: $white-color;
    font-size: 15px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    color: #FFFFFF;
    height: 5rem;
    padding: 5px;

}





.c4Model-images {
    position: relative;
    background-position: center center;
    background-size: cover;
    width: 45%;
    height: -12%;
    left: 35px;
    top: 0;
}

.c4-pad {
    padding-top: 3px;
}




.c4Page_image {
    background-position: center center;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    top: 0;

}

#c4-pad {
    padding: 150px 100px;
}



.consult-img {
    background-position: center center;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}





.mean-container .mean-nav {
    float: right;
    width: 50%;
    background: #ffffff;
    margin-top: 55px;
}




.comming-soon-section {
    /* height: 100vh; */
    overflow: hidden;
}

.comming-soon-section .bg-comming {
    position: relative;
    z-index: -1;
    right: 0;
    top: 17px;
    width: 32rem;
    height: 20rem;
    margin-bottom: 2rem;
}

.comming-soon-item {
    margin-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 50px;
}

.comming-soon-item .element-01 {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: -110px;
}

.comming-soon-item .element-02 {
    position: absolute;
    bottom: -100px;
}

.comming-soon-item .element-03 {
    position: absolute;
    left: 50px;
    bottom: 0px;
}

.comming-soon-item .element-04 {
    position: absolute;
    top: 100px;
    left: 225px;
}

.comming-soon-item .element-05 {
    position: absolute;
    left: 135px;
    bottom: 300px;
}

.comming-soon-item .logo {
    margin-bottom: 60px;
}

.comming-soon-item .content .title {
    font-size: 40px;
    line-height: 1.26;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 32px;
    text-transform: uppercase;
    text-align: left;
    margin-left: 0px;
}

.comming-soon-item .content p {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
    color: #6B6B6B;
    margin-bottom: -16px;
    text-align: left;
}

.comming-soon-item .counter-single-item {
    background: #FDF2F2;
    border: 1px solid #FDF2F2;
    padding: 15px 40px 10px 40px;
}

.comming-soon-item .counter-single-item .counter-item h6 {
    background: transparent;
    color: var(--heading-color);
}

.comming-soon-item .subscribe-form {
    margin-top: 40px;
}

//   .comming-soon-item .subscribe-form.style-01 {
//     padding-right: 50px;
//   }
.comming-soon-item .subscribe-form p {
    font-size: 16px;
    font-weight: 400;
    color: #6B6B6B;
    margin-bottom: 15px;
}

.comming-soon-item .subscribe-form .form-group {
    position: relative;
    z-index: 0;
    margin-bottom: 0;
}

.comming-soon-item .subscribe-form .form-group .form-control {
    height: 60px;
    border: 1px solid #CCCCCC;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 400;
}

.comming-soon-item .subscribe-form .form-group .form-control::-webkit-input-placeholder {
    color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .form-control::-moz-placeholder {
    color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .form-control:-ms-input-placeholder {
    color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .form-control::-ms-input-placeholder {
    color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .form-control::placeholder {
    color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
}

.comming-soon-item .subscribe-form .form-group .btn-wrapper .boxed-btn {
    border-radius: 0;
    padding: 19px 27px;
    font-weight: 700;
    text-transform: uppercase;
    background: #000792;
}

.comming-soon-item .subscribe-form .form-group .btn-wrapper .boxed-btn i {
    margin-left: 10px;
}

.comming-soon-item .social-area {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 35px;
}

.comming-soon-item .social-area p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: #6B6B6B;
    margin-right: 10px;
}

.comming-soon-item .social-area li {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px dashed $green-color;
    border-radius: 3px;
    color: #505050;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
}

.comming-soon-item .social-area li:hover {
    border: 1px solid $green-color;
    background: $green-color;
    color: #fff;
}

.comming-soon-item .social-area li+li {
    margin-left: 10px;
}

.comming-soon-item .social-area li i {
    color: #1e2736;
}


@media only screen and (max-width: 1440px) {
    .comming-soon-item {
        margin-top: 150px;
        padding-left: 50px;
        padding-right: 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .comming-soon-item {
        margin-top: 100px;
        padding-left: 100px;
        padding-right: 100px;
    }

    .comming-soon-item .element-04 {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .comming-soon-item {
        margin-top: 80px;
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .comming-soon-item {
        margin-top: 120px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .comming-soon-item .element-01,
    .comming-soon-item .element-02,
    .comming-soon-item .element-03,
    .comming-soon-item .element-04,
    .comming-soon-item .element-05 {
        display: none;
    }
}



#referral-img {
    position: relative;
    background-position: center center;
    background-size: cover;
    padding-right: 1rem;
    top: 25px;
}

#Referral-section {
    padding-top: 3rem;
}

.ref-test {
    // margin-top: 6rem;
    padding-right: 10px;
}

.ref-test h2{
    font-size: 25px;
}

#ico-comming,
#btn-commingsoon {
    color: #1e2736;
}

#btn-commingsoon {
    position: absolute;
    top: 9px;
    // right: 0px;
    font-size: 20px;
}

#comming-letter {
    border: 1px solid black;
}

#comming-input {
    margin-right: 3rem;
    border: none;
}

#ico-comming {
    right: -5px;
    top: 4px;
}

.about-para {
    color: $heading-color;
}









.other-option i {
    color: $green-color;
}



.tooltip {
    visibility: hidden;
    width: auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: 30rem;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
}

.phone:hover .tooltip,
.mail:hover .tooltip,
.linkedIn:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateY(8px);
}

.phone,
.mail,
.linkedIn {
    padding-left: 4px;
}

.phone i,
.mail i,
.linkedIn i {
    width: 25px;
    height: 25px;
    line-height: 23px;
    background: linear-gradient(0deg, #00c1a2 25%, #00c1a2 100%);
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    transition: 0.5s;
    border: 1px solid transparent;
}

.phone :hover i,
.mail :hover i,
.linkedIn :hover i {
    background: $white-color;
    color: $green-color;
    border: 1px solid $green-color;
    border-radius: 4px;
}


.link,
.search-bar,
.sidebar-toggle {
    width: 30px;
    height: 30px;
    border: 1px solid #00c1a2;
    text-align: center;
    line-height: 27px;
    color: #00c1a2;
    display: inline-block;
    cursor: pointer;
    transition: 0.5s;
    margin-left: 3px;
}














.video-overlay {
    background-position: center center;
    background-size: cover;
    position: relative;
    height: 100%;
    padding-top: 200px;
    padding-bottom: 200px;
    z-index: 0;
}

.video-overlay::before {
    position: absolute;
    content: "";
    background: #464544;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.45;
    z-index: -1;
}

.video-overlay .video-text i {
    font-size: 50px;
    color: $green-color;
}

.video-overlay .video-text p {
    color: $white-color;
    font-size: 20px;
}



// .video-overlay .video-text {
//     margin-bottom: 38px;
//     // display: inline-block;

// }

.whatWeDoHome-img1 {
    background-position: center center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
}

.HomewhatWeDo-section {
    background-color: #1e2736 !important;
}

.aboutc4-2img {
    background-position: center center;
    background-size: cover;
    position: relative;
    height: 530px;
    width: 100%;
    left: 0;
    top: 0;
    padding: 10px 0px 10px 0px;
}

#whatWeDo-pad {
    padding: 75px 75px;
}

.whatWeDo-section {
    background-color: #1e2736 !important;
    /* Set background color */
    // padding: 80px 0 !important; /* Adjust top and bottom padding as needed */
}

.container-fluid {
    // max-width: 1200px !important; /* Set the maximum width of the container */
    margin: 0 auto !important;
    /* Center the container */
}

.whatWeDo-img {
    // width: 100% !important; 
    // // border-radius: 8px !important; 
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    // object-fit: cover !important;
    // height: 100% !important; 
    // display: block;

    background-position: center center;
    background-size: cover;
    position: relative;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: 10px 0px 10px 0px;
}


.tabview-img {
    display: none;
}

#aboutC4-1 {
    padding: 139px 85px;
}

#aboutC4-2 {
    padding: 116px 82px;
}

.platform-box {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid rgba(201, 201, 201, 0.5);
    height: 13rem;
}

.platform-img {
    width: 32rem;
    // height: 14rem;
}

.platformsPage-section {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.platform-content,
.title,
.subtitle {
    text-align: left;
    margin-left: 15px;
    line-height: 1.4;
}

// .platform-content{ 
//     margin-top: 2rem;
// }
.platform-img {
    width: 20.5rem;
    // height: 11.2rem;
}

.counder-border1,
.counder-border2,
.counder-border3 {
    border-right: 2px dashed grey;
}



@media (min-width:991px) and (max-width:1024px) {
    #aboutC4-1 {
        padding: 25px 25px;
    }

    #aboutC4-2 {
        padding: 46px 25px;
    }

    .tabview-img {
        width: 100% !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        object-fit: cover !important;
        height: 100% !important;
        display: block;
    }

    #whatWeDo-pad {
        padding: 24px 30px;

    }

    .service-img {
        height: 27%;
        background-color: #FFFFFF;
        margin-bottom: 0.5rem;
    }

    #referral-bread {
        margin-bottom: -2rem;
    }

  

}







// .people-say-section {
//     margin-top: 48px;
// }

// .people-say-section.style-01 {
//     margin-top: 0px;
// }

// .people-say-section.home-six {
//     margin-top: 74px;
// }

// .people-say-section.people-say-bg {
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     padding: 120px 0px 60px 0px;
//     position: relative;
// }

// .people-say-section.people-say-bg::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: var(--heading-color);
//     opacity: 0.85;
// }

// .people-say-section .shapes .shape-01 {
//     position: absolute;
//     top: 0;
//     left: 24%;
// }

// .people-say-section .shapes .shape-02 {
//     position: absolute;
//     bottom: 0%;
//     left: 8%;
// }

// .people-say-section .shapes .shape-03 {
//     width: 290px;
//     height: 1px;
//     background-color: var(--main-color-one);
//     position: absolute;
//     top: 385px;
//     left: 13%;
//     -webkit-transform: rotate(120deg);
//     transform: rotate(120deg);
//     z-index: 0;
// }

// .people-say-section .shapes .shape-04 {
//     width: 315px;
//     height: 1px;
//     background-color: var(--main-color-one);
//     position: absolute;
//     top: 175px;
//     left: 13%;
//     -webkit-transform: rotate(120deg);
//     transform: rotate(120deg);
//     z-index: 0;
// }

// .people-say-single-item-02 {
//     background: #fff;
//     border-radius: 6px;
// }

// .people-say-single-item-02.style-01 {
//     background: linear-gradient(176deg, rgb(232, 69, 74) 0%, rgb(221, 19, 26) 100%);
// }

// .people-say-single-item-02 .people-say-bg-02 {
//     background-repeat: no-repeat;
//     background-position: 50% 42%;
//     min-height: 480px;
//     border-radius: 6px;
// }

// .people-say-single-item-02 .content .subtitle p {
//     color: var(--main-color-one);
// }

// .people-say-single-item-02 .content .description {
//     color: var(--heading-color);
// }

// .people-say-single-item-02 .content .author-meta {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
// }

// .people-say-single-item-02 .content .author-meta .thumb {
//     margin-right: 20px;
// }

// .people-say-single-item-02 .testimonial-carousel-four .owl-nav .owl-prev {
//     font-size: 28px;
//     position: absolute;
//     top: 82%;
//     right: 22%;
//     -webkit-transition: 0.5s all ease;
//     transition: 0.5s all ease;
// }

// .people-say-single-item-02 .testimonial-carousel-four .owl-nav .owl-prev:hover {
//     color: var(--main-color-one);
// }

// .people-say-single-item-02 .testimonial-carousel-four .owl-nav .owl-next {
//     font-size: 28px;
//     position: absolute;
//     top: 82%;
//     right: 15%;
//     -webkit-transition: 0.5s all ease;
//     transition: 0.5s all ease;
// }

// .people-say-single-item-02 .testimonial-carousel-four .owl-nav .owl-next:hover {
//     color: var(--main-color-one);
// }





.carousel-indicators {
    position: relative !important;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
}





.c4-model1 {
    margin-top: 1rem;
}

.black-box1 {
    border: 1px solid;
    position: relative;
    top: -39px;
    left: 9px;
    font-size: 22px;
    padding: 28px 0px 21px 0px;
    width: 80%;
    background: #1e2736;
    text-align: center;
    border-radius: 20px;
}


.highlight-first-10 {
    box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.1);
    border-radius: 18px;
}



.c4-model2 {
    margin-top: 5rem;
}

.black-box2 {
    border: 1px solid;
    position: relative;
    top: -41px;
    left: 61px;
    font-size: 22px;
    padding: 28px 0px 18px 0px;
    width: 78%;
    background: #1e2736;
    text-align: center;
    border-radius: 20px;

}

// .about-text h2{
//     font-size: 33px;
//     text-transform: initial;
//     color: var(--headingColor);
//     margin-bottom: 10px;
//     font-weight: 500;
// }

#c4Model-part h2{
    font-size: 33px;
    text-transform: initial;
    color: var(--headingColor);
    margin-bottom: 10px;
    font-weight: 500;
}

.highlight-first{
    box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.1);

}

.c4-para1 {
    padding: 65px 0px 0px 18px;
}

.c4-para2 {
    padding: 25px 27px 0px 0px;
}

.content1{
    margin-top: 2rem;
    margin-bottom: -2rem;
}


.about-text1{
    position: relative;
    background: #1e2736;
    padding: 70px 80px;
    margin: 0 0px;
}

.new-image{
    background-position: center center;
    background-size: cover;
    height: 127%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

#new-whatwedo{
    display: none;
}


.navbar.navbar-expand-xl {
    background-color: #1e2736;
    border-bottom:1px solid #00c1a2;
    padding-top: 13px;
    padding-bottom: 13px;
}

.navbar-expand-xl .navbar-nav .nav-link{
    color: #ffffff;
}

.navbar-nav .nav-link.active{
    color: #00c1a2;
}

.navbar-expand-xl .navbar-nav .nav-link a {
    margin-left: 14px !important;
    margin-right: 14px !important;
}

.navbar-nav .nav-item{
    position: relative;
    padding: 10px 8px;
    // border-bottom: 1px solid;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    position: relative;
    top: -1.5px;
}

.navbar-expand-xl .navbar-collapse{
    justify-content: center;
}

.navbar-toggler {
    // color: white;
    background-color: white !important;
}

.navbar-toggler:focus{
    box-shadow: none;
}